
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  transition: Slide
  // pauseOnVisibilityChange,
  // draggable,
  // pauseOnHover
});

export default {
  success : function(message){
    toast.success(message);
  },
  info: function(message){
    toast.info(message);
  },
  warn: function(message){
    toast.warn(message);
  },
  error: function(message){
    toast.error(message);
  }    
}