import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./assets/css/styles.scss";
import store from "./store";
import { RootRouter } from "./services/router";
import * as serviceWorker from "./serviceWorker";

import ReactGA from "react-ga4";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RootRouter />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
