import { http } from '../framework/api';
import { authService } from '../framework/auth';
import storageService from '../services/storage-service'
import config from '../config';

import {
    LOGIN,
    LOGIN_SUCCESSFUL,
    LOGIN_FAIL,

    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESSFUL,
    FORGOT_PASSWORD_FAIL,

    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESSFUL,
    RESET_PASSWORD_FAIL,

    USER_CAN_RESET_PASSWORD,
    USER_CAN_RESET_PASSWORD_SUCCESSFUL,
    USER_CAN_RESET_PASSWORD_FAIL,

    LOGOUT,
    LANGUAGE_CHANGED,
    
    POST_TIMEZONE_LOADING,
    POST_TIMEZONE_LOADING_SUCCESSFUL,
    POST_TIMEZONE_LOADING_FAIL,
} from '../reducers/account.reducer';


export const login = (username, password) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: LOGIN,
            payload: null
        })
        let url = `${config.API_ROOT_URL}/accounts/login`;
        let data = {
            username: username,
            password: password
        }
        http("POST", url, data, false)
            .then(function (res) {
                
                var token = res.Token;
                authService.login(token);

                storageService.setStorageItem(config.TIMEZONE, res.TimeZone)
                storageService.setStorageItem(config.LANG, res.Lang)
                dispatch({
                    type: LANGUAGE_CHANGED,
                    payload: res.Lang
                })

                dispatch({
                    type: LOGIN_SUCCESSFUL,
                    payload: res,
                });

                storageService.setStorageItem(config.USERNAME, res.FirstName + " " + res.LastName);
                
                resolve(res);
            })
            .catch(function (res) {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: res
                });
                reject(res);
            });
    });
export const logoutUser = () => (dispatch) => {
    authService.logout();
    dispatch({
        type: LOGOUT
    })
};

export const forgotPassword = (email) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: FORGOT_PASSWORD,
            payload: []
        })
        let url = `${config.API_ROOT_URL}/accounts/forgot-password?username=${email}`;

        http("GET", url, null, false)
            .then(function (res) {
                dispatch({
                    type: FORGOT_PASSWORD_SUCCESSFUL,
                    payload: res,
                });
                resolve(res);
            })
            .catch(function (res) {
                dispatch({
                    type: FORGOT_PASSWORD_FAIL,
                    payload: res
                });
                reject(res);
            });
    });
export const resetPassword = (email, password, guid) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: RESET_PASSWORD,
            payload: []
        })
        let url = `${config.API_ROOT_URL}/accounts/reset-password`;
        let data = {
            username: email,
            newPassword: password,
            resetGuid: guid
        }
        http("POST", url, data, false)
            .then(function (res) {
                dispatch({
                    type: RESET_PASSWORD_SUCCESSFUL,
                    payload: res,
                });
                resolve(res);
            })
            .catch(function (res) {
                dispatch({
                    type: RESET_PASSWORD_FAIL,
                    payload: res
                });
                reject(res);
            });
    });

export const canUserResetPassword = (email, guid) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: USER_CAN_RESET_PASSWORD,
            payload: []
        })
        let url = `${config.API_ROOT_URL}/accounts/reset-password?email=${email}&guid=${guid}`;

        http("GET", url, null, false)
            .then(function (res) {
                dispatch({
                    type: USER_CAN_RESET_PASSWORD_SUCCESSFUL
                });
                resolve(res);
            })
            .catch(function (res) {
                dispatch({
                    type: USER_CAN_RESET_PASSWORD_FAIL,
                    payload: res
                });
                reject(res);
            });
    });

export const changeUserLang = (lang) => (dispatch) => {
    storageService.setStorageItem(config.LANG, lang);
    
    dispatch({
        type: LANGUAGE_CHANGED,
        payload: lang
    })
};


export const postTimeZone = (id) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: POST_TIMEZONE_LOADING,
            payload: []
        })
        let url = `${config.API_ROOT_URL}/accounts/mytimezone`;
        var data = { id: id };
        http("POST", url, data)
            .then(function (res) {
                dispatch({
                    type: POST_TIMEZONE_LOADING_SUCCESSFUL,
                    payload: res,
                });
                storageService.setStorageItem(config.TIMEZONE, id);
                resolve(res);
            })
            .catch(function (res) {
                dispatch({
                    type: POST_TIMEZONE_LOADING_FAIL,
                    payload: res
                });
                reject(res);
            });
    });

