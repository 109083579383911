import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MasterPage from "../master-page";
import Table from "react-bootstrap/Table";
import localizationService from "../../services/localization-service";
import { getRequest, sendRequest } from "../../actions/portal.action";
import { Oval } from "react-loader-spinner";
import notification from "../../services/notification-service";
import storageService from "../../services/storage-service";
import config from "../../config";
import ModalDeselectGroupInfo from "../../components/modals/modal-deselect-group-info";
import { checkGroupAvailabilityMultiple } from "../../actions/portal.action";
import ClientRequestType from "../../models/ClientRequestType";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

export const ProgramsPage = () => {
  const dispatch = useDispatch();
  // #Region state variables
  const [desktopResolution, setDesktopResolution] = useState(true);

  const [validGroupOptions, setValidGroupOptions] = useState(true);

  const [selectedGroupModal, setSelectedGroupModal] = useState(false);
  const [selectedGroupDay, setSelectedGroupDay] = useState(null);

  const [deselectModalGroupDay, setDeselectModalGroupDay] = useState(null);
  const [deselectModalGroupName, setDeselectModalGroupName] = useState(null);

  const [step, setStep] = useState(1);
  const [disableGoNext, setDisableGoNext] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [submittedGroups, setSubmittedGroups] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [warningMessage2, setWarningMessage2] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [groupsWithSameName, setGroupsWithSameName] = useState([]);
  const [requestType, setRequestType] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [treatmentToShow, setTreatmentToShow] = useState({ TreatmentId: 0 });
  const [clientRequest, setClientRequest] = useState(null);
  const [groupToShow, setGroupToShow] = useState({ GroupId: 0 });
  const requestLoading = useSelector((state) => state.portal.requestLoading);
  const selectedlang = useSelector((state) => state.account.lang);
  const [currentTimeZone, setCurrentTimeZone] = useState(null);
  const [selectedDay, setSelectedDay] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [selectedStartWeek, setSelectedStartWeek] = useState(null);

  const daysForButtons = [
    { text: "M", value: 1 },
    { text: "T", value: 2 },
    { text: "W", value: 3 },
    { text: "T", value: 4 },
    { text: "F", value: 5 },
    { text: "S", value: 6 },
  ];
  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex === availableWeeks.length - 1;
  const [selectedGroup, setSelectedGroup] = useState("");
  const [openDeselectGroupModal, setOpenDeselectGroupModal] = useState(false);
  // let originalGroup = [];

  // #Endregion state variables

  useEffect(() => {
    getUserRequest();
    if (window.innerWidth < 1300) {
      setDesktopResolution(false);
      const mainContainer = Array.from(
        document.querySelectorAll("main.container")
      );
      mainContainer[0].classList.add("mobile");

      const mainPy3 = Array.from(document.querySelectorAll("main.py-3"));

      mainPy3[0].classList.add("mobile");

      return () => {
        mainContainer[0].classList.remove("mobile");
        mainPy3[0].classList.remove("mobile");
      };
    }
  }, []);

  useEffect(() => {
    checkCanGoNext();
    setMobileView();
  }, [step]);

  useEffect(() => {
    const mainContainer = Array.from(
      document.querySelectorAll("main.container")
    );
    mainContainer[0].classList.add("programs-page-container");

    return () => {
      mainContainer[0].classList.remove("programs-page-container");
    };
  }, []);

  useEffect(() => {
    var ctz = storageService.getStorageItem(config.TIMEZONE);
    if (ctz != null) {
      setCurrentTimeZone(ctz);
    }
  }, [currentTimeZone]);

  const updateAvailableWeeks = (availableGroups, selectedGroups) => {
    for (let i = 0; i < availableGroups.length; i++) {
      const obj1 = availableGroups[i];

      const matchingObj = selectedGroups[0].find(
        (obj2) => obj2.StartDate === obj1.StartDate
      );

      if (matchingObj) {
        obj1.Selected = true;
      }
    }
    return availableGroups;
  };

  //When switching through the available weeks selection
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % availableWeeks.length);
    handleChange(
      availableWeeks[(currentIndex + 1) % availableWeeks.length]?.value
    );
  };

  //When switching through the available weeks selection
  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + availableWeeks.length) % availableWeeks.length
    );
    handleChange(
      availableWeeks[
        (currentIndex - 1 + availableWeeks.length) % availableWeeks.length
      ]?.value
    );
  };

  //Get available groups when Week Start is changed
  const handleChange = async (selectedWeekStart) => {
    if (selectedGroups?.[0].length == 0 || !selectedGroups)
      setSelectedStartWeek(selectedWeekStart);
    let availableGroups = await checkGroupAvailability(
      selectedWeekStart,
      clientRequest?.[0]?.TreatmentId,
      clientRequest?.[0]?.EnrolledGroupId
    );
    availableGroups = updateAvailableWeeks(availableGroups, selectedGroups);
    let updatedClientRequest = clientRequest;
    updatedClientRequest[0].SingleGroupResponse = availableGroups;
    if (selectedGroups?.[0].length > 1 || selectedGroups) {
      for (const obj of updatedClientRequest[0].SingleGroupResponse) {
        selectedGroups[0].forEach((sg) => {
          if (sg.GroupId === obj.GroupId && sg.StartDate === obj.StartDate)
            obj.Selected = true;
          else obj.Selected = false;
        });
      }
    }
    setClientRequest(updatedClientRequest);
    setGroupData(updatedClientRequest);
    setGroupToShow(updatedClientRequest[0].SingleGroupResponse[0]);
  };

  const checkGroupAvailability = async (
    startWeek,
    TreatmentId,
    EnrolledGroupId
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        checkGroupAvailabilityMultiple(startWeek, TreatmentId, EnrolledGroupId)
      )
        .then(async function (response) {
          resolve(response.Result.SingleGroupResponse);
        })
        .catch(function (res) {
          notification.error(
            localizationService.getLocalizationValue(
              "programSelectionError",
              selectedlang
            )
          );
          reject(res);
        });
    });
  };

  //Get Static List of Mondays for the next "numWeeks" weeks
  const getAvailableWeeks = (numWeeks) => {
    const dates = [];
    const today = new Date();
    const nextMonday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + ((1 + 7 - today.getDay()) % 7)
    );

    for (let i = 0; i < numWeeks; i++) {
      const date = new Date(
        nextMonday.getFullYear(),
        nextMonday.getMonth(),
        nextMonday.getDate() + i * 7
      );
      const day = date.toLocaleString("en-us", { weekday: "long" });
      const month = date.toLocaleString("en-us", { month: "long" });
      const dayOfMonth = date.getDate();
      const year = date.getFullYear();

      const formattedDate = `${day}, ${month} ${dayOfMonth}, ${year}`;
      dates.push({ label: formattedDate, value: formattedDate });
    }

    setAvailableWeeks(dates);
    return dates;
  };

  const getUserRequest = () => {
    dispatch(getRequest())
      .then(async function (response) {
        if (response.length !== 0) {
          //Handle Switch Requests
          if (response[0].Status !== 1) {
            if (response?.[0]?.Type === ClientRequestType.Switch) {
              setRequestType(ClientRequestType.Switch);
              setValidGroupOptions(false);
            } else {
              setValidGroupOptions(
                response.some((obj) => obj.SingleGroupResponse.length === 0)
              );
            }
            setSubmitted(true);
            setTreatmentToShow(response[0]);
            setSubmittedGroups(response);
            setGroupData(response);
          } else {
            if (response?.[0]?.Type === ClientRequestType.Switch) {
              setValidGroupOptions(false);
              let weeks = getAvailableWeeks(12);
              setRequestType(1);
              setSelectedStartWeek(weeks?.[0]?.value);
              let availableGroups = await checkGroupAvailability(
                weeks?.[0]?.value,
                response?.[0]?.TreatmentId,
                response?.[0]?.EnrolledGroupId
              );
              response[0].SingleGroupResponse = availableGroups;
            } else {
              setValidGroupOptions(
                response.some((obj) => obj.SingleGroupResponse.length === 0)
              );
            }
            setEndDate(response[0].EndDate);
            setStartDate(response[0].StartDate);
            setClientRequest(response);
            // for mobile reso
            setGroupToShow(response[0].SingleGroupResponse[0]);
            let groupsByDay = response[0].SingleGroupResponse.filter(
              (x) => x.StartDateDay == 1
            );
            showGroupInfo(groupsByDay[0]);

            // for desktop reso
            setGroupData(response);
            setResponseData(response);
            setSelectedGroups(new Array(response.length).fill([]));
          }
        } else {
          setValidGroupOptions(false);
        }
      })
      .catch(function (res) {
        setValidGroupOptions(false);
      });
  };

  //#region Desktop reso methods

  const incrementStep = () => {
    setStep(step + 1);
    setSelectedDay(1);
    setGroupsWithSameName([]);
    const currentTreatment = groupData.find(
      (treatment) => treatment.CountId === step + 1
    );
    const defaultGroup = currentTreatment?.SingleGroupResponse.find(
      (group) => group.StartDateDay === 1
    );

    if (defaultGroup) {
      setGroupToShow(defaultGroup);
      showGroupInfo(groupToShow);
    }
    setGroupToShow({ GroupId: 0 });
  };

  const decrementStep = () => {
    setStep(step - 1);
    setSelectedDay(1);
    setSelectedGroupDay(null);
    setDeselectModalGroupDay(null);

    if (groupToShow && groupToShow.GroupName) {
      const updatedGroupsWithSameName = groupData[
        step - 2
      ].SingleGroupResponse.filter(
        (group) =>
          group.StartDateDay === 1 && group.GroupName === groupToShow.GroupName
      );

      setGroupsWithSameName(updatedGroupsWithSameName);
    }
    setGroupToShow({ GroupId: 0 });
  };
  const fillDeselectModalInfo = (group) => {
    if (!group) {
      return;
    }

    const groupDay = group.StartDateDay;
    const groupName = group.GroupName;

    setDeselectModalGroupDay(groupDay);
    setDeselectModalGroupName(groupName);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const Submit = () => {
    dispatch(sendRequest(selectedGroups))
      .then(function (response) {
        notification.success(
          localizationService.getLocalizationValue(
            "programSelectionSuccess",
            selectedlang
          )
        );
        //Refreshing the page instead of getting user request (temp fix for html breaking)
        handleRefresh();
        //getUserRequest();
      })
      .catch(function (res) {
        notification.error(
          localizationService.getLocalizationValue(
            "programSelectionError",
            selectedlang
          )
        );
      });
  };
  const canSelectGroup = () => {
    let canSelect = true;
    // check if there are already 2 groups selected from Treatment
    if (selectedGroups[step - 1].length > 1) {
      canSelect = false;
    }
    //check if group that is already selected have enough space, if not, we allow selection of one more
    if (
      selectedGroups[step - 1].length === 1 &&
      selectedGroups[step - 1][0].Spaces > 2
    ) {
      canSelect = false;
    }

    return canSelect;
  };

  const checkCanGoNext = () => {
    let disable = true;

    if (
      selectedGroups &&
      selectedGroups.length > 0 &&
      selectedGroups.length > step - 1
    ) {
      if (
        selectedGroups[step - 1].length === 1 &&
        selectedGroups[step - 1][0].Spaces > 2
      ) {
        disable = false;
      }

      if (selectedGroups[step - 1].length === 2) {
        disable = false;
      }

      if (
        selectedGroups[step - 1].length === 1 &&
        selectedGroups[step - 1][0].Spaces < 3 &&
        groupData[step - 1].length === 1
      ) {
        disable = false;
      }

      if (
        selectedGroups[step - 1].length === 1 &&
        selectedGroups[step - 1][0].Spaces < 3 &&
        responseData[step - 1].SingleGroupResponse.length === 1
      ) {
        disable = false;
      }

      setDisableGoNext(disable);
    }
  };

  const selectGroup = (data, groupId) => {
    if (!disableGoNext) {
      const currentTreatment = groupData.find(
        (treatment) => treatment.CountId === step
      );

      if (currentTreatment) {
        const selectedGroupsForTreatment = selectedGroups[step - 1];

        const selectedGroupForTreatment = selectedGroupsForTreatment.find(
          (group) => group.TreatmentId === currentTreatment.TreatmentId
        );

        if (selectedGroupForTreatment) {
          const groupDay = selectedGroupForTreatment.StartDateDay;
          const groupName = selectedGroupForTreatment.GroupName;

          setDeselectModalGroupDay(groupDay);
          setDeselectModalGroupName(groupName);
          setSelectedGroup(groupName);
          setSelectedGroupDay(groupDay);
        }
      }
      fillDeselectModalInfo();
      setOpenDeselectGroupModal(true);

      return;
    }

    if (responseData[step - 1].SingleGroupResponse.length === 1) {
      setWarningMessage2(false);
    }

    if (data.Spaces < 3 && selectedGroups[step - 1].length === 0) {
      setSelectedGroupModal(true);
    }

    let groupSelected = false;

    if (canSelectGroup()) {
      const temp = JSON.parse(JSON.stringify(groupData));
      temp[step - 1].SingleGroupResponse.find(
        (x) => x.GroupId == groupId
      ).Selected = true;

      setGroupData(temp);

      const original = selectedGroups;

      const tempselectedGroups = [...original[step - 1], data];

      original[step - 1] = tempselectedGroups;
      setSelectedGroups(original);
      checkCanGoNext();
      groupSelected = true;
      //deselect modal info
    }

    return groupSelected;
  };

  const deselectGroup = (data, groupId) => {
    const temp = JSON.parse(JSON.stringify(groupData));
    temp[step - 1].SingleGroupResponse.find(
      (x) => x.GroupId == groupId
    ).Selected = false;
    setGroupData(temp);

    const list = selectedGroups;
    const sublist = list[step - 1];

    list[step - 1] = sublist.filter((x) => x.GroupId !== data.GroupId);

    setSelectedGroups(list);
    checkCanGoNext();
  };

  const filterGroupsByDay = (day, isMobile) => {
    setSelectedDay(day);

    if (isMobile) {
      let groupsByDay = groupData[step - 1].SingleGroupResponse.filter(
        (x) => x.StartDateDay == day
      );
      showGroupInfo(groupsByDay[0]);
    }
  };

  useEffect(() => {
    if (groupToShow && groupToShow.GroupName) {
      const updatedGroupsWithSameName = groupData[
        step - 1
      ].SingleGroupResponse.filter(
        (group) =>
          group.StartDateDay === 1 && group.GroupName === groupToShow.GroupName
      );

      setGroupsWithSameName(updatedGroupsWithSameName);
    }
  }, [groupToShow]);

  const displayDay = (day) => {
    let selectedDay = "";
    switch (day) {
      case 1:
        selectedDay = "Monday";
        break;
      case 2:
        selectedDay = "Tuesday";
        break;
      case 3:
        selectedDay = "Wednesday";
        break;
      case 4:
        selectedDay = "Thursday";
        break;
      case 5:
        selectedDay = "Friday";
        break;
      case 6:
        selectedDay = "Saturday";
        break;
      default:
        break;
    }

    return selectedDay;
  };

  //#endregion Desktop reso methods

  //#region Mobile reso methods

  const showGroupInfo = (group) => {
    setGroupToShow(group);
    // setIsGroupSelected(false);
  };

  const showSelectedTreatment = (index) => {
    if (submitted) {
      setTreatmentToShow(submittedGroups[index]);
    } else {
      setTreatmentToShow(selectedGroups[index]);
    }
  };

  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  const selectGroupMobile = (groupId) => {
    if (!disableGoNext && requestType != ClientRequestType.Switch) {
      const currentTreatment = groupData.find(
        (treatment) => treatment.CountId === step
      );

      if (currentTreatment) {
        const selectedGroupsForTreatment = selectedGroups[step - 1];

        const selectedGroupForTreatment = selectedGroupsForTreatment.find(
          (group) => group.TreatmentId === currentTreatment.TreatmentId
        );

        if (selectedGroupForTreatment) {
          const groupDay = selectedGroupForTreatment.StartDateDay;
          const groupName = selectedGroupForTreatment.GroupName;

          setDeselectModalGroupDay(groupDay);
          setDeselectModalGroupName(groupName);
        }
      }
      fillDeselectModalInfo();
      setOpenDeselectGroupModal(true);

      return;
    }

    if (groupData[step - 1]?.SingleGroupResponse) {
      const activeGroup = groupData[step - 1].SingleGroupResponse.find(
        (group) => group.GroupId === groupId
      );

      if (activeGroup) {
        const groupSelected = selectGroup(activeGroup, groupId);

        if (groupSelected) {
          const updatedGroupData = [...groupData];
          const groupIndex = updatedGroupData[
            step - 1
          ].SingleGroupResponse.findIndex((group) => group.GroupId === groupId);

          const groupName = activeGroup.GroupName;
          const groupDay = activeGroup.StartDateDay;

          updatedGroupData[step - 1].SingleGroupResponse[groupIndex] = {
            ...activeGroup,
            Selected: true,
          };

          setGroupData(updatedGroupData);
          setGroupToShow({ ...activeGroup, Selected: true });

          setSelectedGroupDay(groupDay);
          setSelectedGroup(groupName);

          setDeselectModalGroupDay(groupDay);
          setDeselectModalGroupName(groupName);
        }
      }
    }
  };

  const deSelectGroupMobile = (groupId) => {
    if (groupData[step - 1]?.SingleGroupResponse) {
      const activeGroup = groupData[step - 1].SingleGroupResponse.find(
        (group) => group.GroupId === groupId
      );

      if (activeGroup) {
        deselectGroup(activeGroup, groupId);

        const updatedGroupData = [...groupData];
        const groupIndex = updatedGroupData[
          step - 1
        ].SingleGroupResponse.findIndex((group) => group.GroupId === groupId);

        if (groupIndex !== -1) {
          updatedGroupData[step - 1].SingleGroupResponse[groupIndex] = {
            ...activeGroup,
            Selected: false,
          };

          setGroupData(updatedGroupData);
          setGroupToShow({ ...groupToShow, Selected: false });
        }
      }
    }
  };

  const setMobileView = () => {
    if (
      step > 0 &&
      groupData &&
      groupData.length > 0 &&
      step <= groupData.length
    ) {
      setGroupToShow(groupData[step - 1].SingleGroupResponse[0]);
    }

    if (step > 1 && step > groupData.length) {
      showSelectedTreatment(0);
    }
  };
  const closeDeselectModal = () => {
    setOpenDeselectGroupModal(false);
  };

  const showGroupInfoByGroupName = (groupName) => {
    setGroupsWithSameName([]);

    const groupsWithSameName = groupData[step - 1].SingleGroupResponse.filter(
      (group) =>
        group.StartDateDay === selectedDay && group.GroupName === groupName
    );

    if (groupsWithSameName.length > 0) {
      setGroupsWithSameName(groupsWithSameName);
      const selectedGroup = groupsWithSameName[0];
      setGroupToShow(selectedGroup);
    }
  };
  //#endregion Mobile reso methods
  return (
    <MasterPage>
      <React.Fragment>
        <div
          className="overlay"
          style={requestLoading === false ? { display: "none" } : {}}
        >
          <Oval
            display="block"
            marginLeft="auto"
            marginRight="auto"
            color="#00BFFF"
            secondaryColor="#99e6ff"
            height={100}
            width={100}
            visible={requestLoading}
            ariaLabel="loading-indicator"
          />
        </div>
        <ModalDeselectGroupInfo
          selectedStartWeek={selectedStartWeek}
          requestType={requestType}
          group={selectedGroup}
          day={displayDay(selectedGroupDay)}
          openModal={openDeselectGroupModal}
          closeModal={closeDeselectModal}
        ></ModalDeselectGroupInfo>
        {currentTimeZone ? (
          requestLoading === false ? (
            <div>
              <div
                className="modal fade show"
                id="wagon-confirm-dialog"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                style={
                  selectedGroupModal === true
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header programs">
                      <h6
                        className="modal-title text-white"
                        id="exampleModalLabel"
                      >
                        {localizationService.getLocalizationValue(
                          "selectGroupTitle",
                          selectedlang
                        )}
                      </h6>
                    </div>
                    <div className="modal-body">
                      <div className="container mt-3">
                        <div className="row">
                          <div className="col">
                            <div className="program-warning">
                              {warningMessage2 == true ? (
                                <div>
                                  {localizationService.getLocalizationValue(
                                    "programWarningMessage1",
                                    selectedlang
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {localizationService.getLocalizationValue(
                                    "programWarningMessage3",
                                    selectedlang
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                    <div className="modal-footer">
                      <div className="mx-auto">
                        <button
                          className="btn btn-secondary program-color"
                          onClick={() => setSelectedGroupModal(false)}
                        >
                          {" "}
                          {localizationService.getLocalizationValue(
                            "confirm",
                            selectedlang
                          )}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade show"
                id="wagon-confirm-dialog"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                style={
                  validGroupOptions === true
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header programs">
                      <h6
                        className="modal-title text-white"
                        id="exampleModalLabel"
                      >
                        {localizationService.getLocalizationValue(
                          "noGroupsAvailable",
                          selectedlang
                        )}
                      </h6>
                    </div>
                    <div className="modal-body">
                      <div className="container mt-3">
                        <div className="row">
                          <div className="col">
                            <div className="program-warning">
                              <div>
                                {localizationService.getLocalizationValue(
                                  "programWarningMessage4",
                                  selectedlang
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                    <div className="modal-footer">
                      <div className="mx-auto">
                        <button
                          className="btn btn-secondary"
                          onClick={() => setValidGroupOptions(false)}
                        >
                          {" "}
                          {localizationService.getLocalizationValue(
                            "close",
                            selectedlang
                          )}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {groupData.length === 0 && submittedGroups.length === 0 ? (
                <div className="alert alert-warning">
                  {localizationService.getLocalizationValue(
                    "noClientRequest",
                    selectedlang
                  )}
                </div>
              ) : desktopResolution ? (
                <div>
                  {submitted === true ? (
                    <div className="submitted-requests-container">
                      <div>
                        <h4>
                          {localizationService.getLocalizationValue(
                            "submittedRequest",
                            selectedlang
                          )}
                        </h4>
                      </div>
                      <div className="table-box">
                        <Table responsive className="groupTable">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>
                                {localizationService.getLocalizationValue(
                                  "treatmentsTitle",
                                  selectedlang
                                )}
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Group</th>
                              {requestType === ClientRequestType.Switch ? (
                                <th style={{ whiteSpace: "nowrap" }}>
                                  {localizationService.getLocalizationValue(
                                    "switchStartDateTime",
                                    selectedlang
                                  )}
                                </th>
                              ) : (
                                <th style={{ whiteSpace: "nowrap" }}>
                                  {localizationService.getLocalizationValue(
                                    "startDateTime",
                                    selectedlang
                                  )}
                                </th>
                              )}
                              <th style={{ whiteSpace: "nowrap" }}>
                                {localizationService.getLocalizationValue(
                                  "counsellor",
                                  selectedlang
                                )}
                              </th>
                              <th
                                style={{ whiteSpace: "nowrap" }}
                                className="text-center"
                              >
                                {localizationService.getLocalizationValue(
                                  "spaces",
                                  selectedlang
                                )}
                              </th>
                              {requestType === ClientRequestType.Switch ? (
                                <></>
                              ) : (
                                <>
                                  <th
                                    style={{ whiteSpace: "nowrap" }}
                                    className="text-center"
                                  >
                                    {localizationService.getLocalizationValue(
                                      "sessionHeading",
                                      selectedlang
                                    )}
                                  </th>
                                </>
                              )}
                              <th
                                style={{ whiteSpace: "nowrap" }}
                                className="text-center"
                              >
                                {localizationService.getLocalizationValue(
                                  "status",
                                  selectedlang
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {submittedGroups.map((treatment, index) => (
                              <tr key={index}>
                                <td>
                                  {
                                    treatment.SingleGroupResponse[0]
                                      .TreatmentName
                                  }
                                </td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {treatment.SingleGroupResponse.map(
                                    (group, index2) => (
                                      <div key={index2}>{group.GroupName}</div>
                                    )
                                  )}
                                </td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {treatment.SingleGroupResponse.map(
                                    (group, index2) => (
                                      <div key={index2}>{group.StartDate}</div>
                                    )
                                  )}
                                </td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {treatment.SingleGroupResponse.map(
                                    (group, index2) => (
                                      <div key={index2}>{group.Counsellor}</div>
                                    )
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {treatment.SingleGroupResponse.map(
                                    (group, index2) => (
                                      <div className="text-center" key={index2}>
                                        {group.Spaces}
                                      </div>
                                    )
                                  )}
                                </td>
                                {requestType === ClientRequestType.Switch ? (
                                  <></>
                                ) : (
                                  <>
                                    <td
                                      className="text-center"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {treatment.SingleGroupResponse.map(
                                        (group, index2) => (
                                          <div
                                            className="text-center"
                                            key={index2}
                                          >
                                            {group.NumberOfSessions}
                                          </div>
                                        )
                                      )}
                                    </td>
                                  </>
                                )}
                                <td className="text-center">
                                  <div
                                    className="status-badge-box"
                                    style={{
                                      height:
                                        treatment.SingleGroupResponse.length ===
                                        1
                                          ? "auto"
                                          : "",
                                    }}
                                  >
                                    {treatment.SingleGroupResponse.map(
                                      (group, index2) =>
                                        group.Status === 2 ? (
                                          <span
                                            key={index2}
                                            className="badge badge-pill badge-warning status-badge"
                                          >
                                            {localizationService.getLocalizationValue(
                                              "pending",
                                              selectedlang
                                            )}
                                          </span>
                                        ) : group.Status === 3 ? (
                                          <span
                                            key={index2}
                                            className="badge badge-pill badge-success status-badge"
                                          >
                                            {localizationService.getLocalizationValue(
                                              "approved",
                                              selectedlang
                                            )}
                                          </span>
                                        ) : (
                                          <span
                                            key={index2}
                                            class="badge badge-pill badge-danger status-badge"
                                          >
                                            {localizationService.getLocalizationValue(
                                              "rejected",
                                              selectedlang
                                            )}
                                          </span>
                                        )
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="stepper-wrapper">
                        {groupData.map((groupData, index) => (
                          <div
                            key={index}
                            className={
                              "stepper-item " +
                              (step === groupData.CountId
                                ? "active"
                                : step > groupData.CountId
                                ? "completed"
                                : null)
                            }
                          >
                            <div className="step-counter">
                              {groupData.CountId}
                            </div>
                            <div className="step-name">
                              {groupData.TreatmentName}
                            </div>
                          </div>
                        ))}
                        <div
                          className={
                            "stepper-item " +
                            (step > groupData.length ? "active" : null)
                          }
                        >
                          <div className="step-counter">
                            {groupData.length + 1}
                          </div>
                          <div className="step-name">
                            {localizationService.getLocalizationValue(
                              "reviewAndSubmit",
                              selectedlang
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="nav-buttons"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{ marginRight: 5 }}
                          className="btn btn-secondary program-color"
                          onClick={decrementStep}
                          disabled={step === 1}
                        >
                          {localizationService.getLocalizationValue(
                            "previous",
                            selectedlang
                          )}
                        </button>
                        {step > groupData.length ? (
                          <button
                            className="btn btn-secondary program-color"
                            onClick={Submit}
                          >
                            {localizationService.getLocalizationValue(
                              "submit",
                              selectedlang
                            )}
                          </button>
                        ) : (
                          <button
                            className="btn btn-secondary program-color"
                            onClick={incrementStep}
                            disabled={disableGoNext}
                          >
                            {localizationService.getLocalizationValue(
                              "onboarding_next_btn",
                              selectedlang
                            )}
                          </button>
                        )}
                      </div>
                      {requestType === ClientRequestType.Switch ? (
                        <>
                          {" "}
                          <h4>
                            {step === 1
                              ? localizationService.getLocalizationValue(
                                  "programsTitleSwitch",
                                  selectedlang
                                )
                              : step === groupData.length + 1
                              ? localizationService.getLocalizationValue(
                                  "reviewAndSubmit",
                                  selectedlang
                                )
                              : localizationService.getLocalizationValue(
                                  "programsTitleSwitch",
                                  selectedlang
                                )}
                          </h4>
                        </>
                      ) : (
                        <>
                          {" "}
                          <h4>
                            {step === 1
                              ? localizationService.getLocalizationValue(
                                  "programsTitle",
                                  selectedlang
                                )
                              : step === groupData.length + 1
                              ? localizationService.getLocalizationValue(
                                  "reviewAndSubmit",
                                  selectedlang
                                )
                              : localizationService.getLocalizationValue(
                                  "programsTitle",
                                  selectedlang
                                )}
                          </h4>
                        </>
                      )}
                      <div className="step-one-intro-box">
                        {step === 1 ? (
                          <>
                            {requestType !== 1 ? (
                              <>
                                <p className="step-one-intro">
                                  {localizationService.getLocalizationValue(
                                    "introParagraphPart1",
                                    selectedlang
                                  )}
                                  <b>{endDate}</b>.{" "}
                                  {localizationService.getLocalizationValue(
                                    "introParagraphPart2",
                                    selectedlang
                                  )}{" "}
                                  <b>{startDate}</b>.
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="step-one-intro">
                                  {localizationService.getLocalizationValue(
                                    "switchParagraphPart1",
                                    selectedlang
                                  )}
                                  <b>{groupData?.[0]?.TreatmentName}</b>.{" "}
                                  {localizationService.getLocalizationValue(
                                    "switchParagraphPart2",
                                    selectedlang
                                  )}
                                </p>
                              </>
                            )}
                            <p className="step-one-intro">
                              {localizationService.getLocalizationValue(
                                "introParagraphPart3",
                                selectedlang
                              )}
                            </p>
                            {submittedGroups.length > 1 ? (
                              <p className="step-one-intro">
                                {localizationService.getLocalizationValue(
                                  "introParagraphPart4",
                                  selectedlang
                                )}
                              </p>
                            ) : null}
                            {requestType === ClientRequestType.Switch ? (
                              <>
                                <br></br>
                                <h4>
                                  {localizationService.getLocalizationValue(
                                    "selectFirstAvailableWeek",
                                    selectedlang
                                  )}
                                </h4>
                                <div>
                                  <div>
                                    <button
                                      style={{ marginRight: 10 }}
                                      className="btn btn-secondary program-color"
                                      disabled={isAtStart}
                                      onClick={handlePrevious}
                                    >
                                      {localizationService.getLocalizationValue(
                                        "previous",
                                        selectedlang
                                      )}
                                    </button>
                                    <span>
                                      {availableWeeks[currentIndex].value}
                                    </span>
                                    <button
                                      style={{ marginLeft: 10 }}
                                      className="btn btn-secondary program-color"
                                      disabled={isAtEnd}
                                      onClick={handleNext}
                                    >
                                      {localizationService.getLocalizationValue(
                                        "next",
                                        selectedlang
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : step === groupData.length + 1 ? (
                          <>
                            <p className="step-one-intro">
                              {localizationService.getLocalizationValue(
                                "introParagraphPart5",
                                selectedlang
                              )}
                            </p>
                            <p className="step-one-intro">
                              {localizationService.getLocalizationValue(
                                "introParagraphPart6",
                                selectedlang
                              )}
                            </p>
                          </>
                        ) : (
                          <p className="step-one-intro">
                            {localizationService.getLocalizationValue(
                              "introParagraphPart7",
                              selectedlang
                            )}
                          </p>
                        )}
                      </div>
                      {step <= groupData.length ? (
                        <div className="desktop-day-container">
                          <h4>
                            {localizationService.getLocalizationValue(
                              "selectADay",
                              selectedlang
                            )}
                          </h4>
                          {daysForButtons.map((day) => (
                            <button
                              className={
                                "day-btn" +
                                (selectedDay === day.value ? " active" : "")
                              }
                              key={day.value}
                              onClick={() =>
                                filterGroupsByDay(day.value, false)
                              }
                            >
                              {day.text}
                            </button>
                          ))}
                        </div>
                      ) : null}
                      {step <= groupData.length ? (
                        groupData[step - 1].SingleGroupResponse.filter(
                          (x) => x.StartDateDay == selectedDay
                        ).length > 0 ? (
                          <div>
                            <div className="table-box">
                              <Table responsive className="groupTable">
                                <thead>
                                  <tr>
                                    <th
                                      style={{ whiteSpace: "nowrap" }}
                                      colSpan="6"
                                    >
                                      {localizationService.getLocalizationValue(
                                        "groupName",
                                        selectedlang
                                      )}
                                    </th>
                                    <th style={{ whiteSpace: "nowrap" }}>
                                      {localizationService.getLocalizationValue(
                                        "group",
                                        selectedlang
                                      )}
                                    </th>
                                    <th style={{ whiteSpace: "nowrap" }}>
                                      {requestType ===
                                      ClientRequestType.Switch ? (
                                        <>
                                          {localizationService.getLocalizationValue(
                                            "switchStartDateTime",
                                            selectedlang
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {localizationService.getLocalizationValue(
                                            "startDateTime",
                                            selectedlang
                                          )}
                                        </>
                                      )}
                                    </th>
                                    {requestType == ClientRequestType.Switch ? (
                                      <></>
                                    ) : (
                                      <>
                                        <th style={{ whiteSpace: "nowrap" }}>
                                          {localizationService.getLocalizationValue(
                                            "endDateTime",
                                            selectedlang
                                          )}
                                        </th>
                                      </>
                                    )}
                                    <th style={{ whiteSpace: "nowrap" }}>
                                      {localizationService.getLocalizationValue(
                                        "counsellor",
                                        selectedlang
                                      )}
                                    </th>
                                    <th
                                      style={{ whiteSpace: "nowrap" }}
                                      className="text-center"
                                    >
                                      {localizationService.getLocalizationValue(
                                        "spaces",
                                        selectedlang
                                      )}
                                    </th>
                                    {requestType == ClientRequestType.Switch ? (
                                      <></>
                                    ) : (
                                      <>
                                        <th
                                          style={{ whiteSpace: "nowrap" }}
                                          className="text-center"
                                        >
                                          {localizationService.getLocalizationValue(
                                            "sessionHeading",
                                            selectedlang
                                          )}
                                        </th>
                                      </>
                                    )}
                                    <th
                                      style={{ whiteSpace: "nowrap" }}
                                      className="text-center"
                                    >
                                      {localizationService.getLocalizationValue(
                                        "action",
                                        selectedlang
                                      )}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {groupData[
                                    step - 1
                                  ].SingleGroupResponse.filter(
                                    (x) => x.StartDateDay == selectedDay
                                  ).map((group, index) => (
                                    <tr key={group.GroupId}>
                                      <td colSpan="6">{group.FullGroupName}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {group.GroupName}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {group.StartDate}
                                      </td>
                                      {requestType ==
                                      ClientRequestType.Switch ? (
                                        <></>
                                      ) : (
                                        <>
                                          <td style={{ whiteSpace: "nowrap" }}>
                                            {group.EndDate}
                                          </td>
                                        </>
                                      )}
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {group.Counsellor}
                                      </td>
                                      <td
                                        style={{ whiteSpace: "nowrap" }}
                                        className="text-center"
                                      >
                                        {group.Spaces}
                                      </td>
                                      {requestType ==
                                      ClientRequestType.Switch ? (
                                        <></>
                                      ) : (
                                        <>
                                          <td
                                            style={{ whiteSpace: "nowrap" }}
                                            className="text-center"
                                          >
                                            {group.NumberOfSessions}
                                          </td>
                                        </>
                                      )}
                                      {group.Selected === true ? (
                                        <td className="text-center">
                                          <button
                                            className="btn btn-secondary programs"
                                            onClick={() =>
                                              deselectGroup(
                                                group,
                                                group.GroupId
                                              )
                                            }
                                          >
                                            <i className="fa-solid fa-x"></i>{" "}
                                            {localizationService.getLocalizationValue(
                                              "deselect",
                                              selectedlang
                                            )}{" "}
                                          </button>
                                        </td>
                                      ) : (
                                        <td className="text-center">
                                          <button
                                            className="btn btn-secondary program-color"
                                            onClick={() =>
                                              selectGroup(group, group.GroupId)
                                            }
                                          >
                                            {" "}
                                            {localizationService.getLocalizationValue(
                                              "select",
                                              selectedlang
                                            )}{" "}
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <p className="no-groups-on-day">
                              {localizationService.getLocalizationValue(
                                "noGroupsOnDate",
                                selectedlang
                              )}{" "}
                            </p>
                          </div>
                        )
                      ) : (
                        <div>
                          <div>
                            <Table responsive className="groupTable">
                              <thead>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {localizationService.getLocalizationValue(
                                      "treatmentsTitle",
                                      selectedlang
                                    )}
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {localizationService.getLocalizationValue(
                                      "group",
                                      selectedlang
                                    )}
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {requestType ===
                                    ClientRequestType.Switch ? (
                                      <>
                                        {localizationService.getLocalizationValue(
                                          "switchStartDateTime",
                                          selectedlang
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {localizationService.getLocalizationValue(
                                          "startDateTime",
                                          selectedlang
                                        )}
                                      </>
                                    )}
                                  </th>
                                  {requestType == ClientRequestType.Switch ? (
                                    <></>
                                  ) : (
                                    <>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        {localizationService.getLocalizationValue(
                                          "endDateTime",
                                          selectedlang
                                        )}
                                      </th>
                                    </>
                                  )}
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {localizationService.getLocalizationValue(
                                      "counsellor",
                                      selectedlang
                                    )}
                                  </th>
                                  <th
                                    style={{ whiteSpace: "nowrap" }}
                                    className="text-center"
                                  >
                                    {localizationService.getLocalizationValue(
                                      "spaces",
                                      selectedlang
                                    )}
                                  </th>
                                  {requestType == ClientRequestType.Switch ? (
                                    <></>
                                  ) : (
                                    <>
                                      <th
                                        style={{ whiteSpace: "nowrap" }}
                                        className="text-center"
                                      >
                                        {localizationService.getLocalizationValue(
                                          "sessionHeading",
                                          selectedlang
                                        )}
                                      </th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {selectedGroups.map(
                                  (ProgramSelections, index) => (
                                    <tr key={index}>
                                      <td>
                                        {ProgramSelections[0].TreatmentName}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {ProgramSelections.map(
                                          (group, index2) => (
                                            <div key={index2}>
                                              {group.GroupName}
                                            </div>
                                          )
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {ProgramSelections.map(
                                          (group, index2) => (
                                            <div key={index2}>
                                              {group.StartDate}
                                            </div>
                                          )
                                        )}
                                      </td>
                                      {requestType ==
                                      ClientRequestType.Switch ? (
                                        <></>
                                      ) : (
                                        <>
                                          <td style={{ whiteSpace: "nowrap" }}>
                                            {ProgramSelections.map(
                                              (group, index2) => (
                                                <div key={index2}>
                                                  {group.EndDate}
                                                </div>
                                              )
                                            )}
                                          </td>
                                        </>
                                      )}
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {ProgramSelections.map(
                                          (group, index2) => (
                                            <div key={index2}>
                                              {group.Counsellor}
                                            </div>
                                          )
                                        )}
                                      </td>
                                      <td
                                        style={{ whiteSpace: "nowrap" }}
                                        className="text-center"
                                      >
                                        {ProgramSelections.map(
                                          (group, index2) => (
                                            <div key={index2}>
                                              {group.Spaces}
                                            </div>
                                          )
                                        )}
                                      </td>
                                      {requestType ==
                                      ClientRequestType.Switch ? (
                                        <></>
                                      ) : (
                                        <>
                                          <td
                                            style={{ whiteSpace: "nowrap" }}
                                            className="text-center"
                                          >
                                            {ProgramSelections.map(
                                              (group, index2) => (
                                                <div key={index2}>
                                                  {group.NumberOfSessions}
                                                </div>
                                              )
                                            )}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : submitted ? (
                <div className="main-container-mobile">
                  <div className="content-container-mobile submitted-request">
                    <h4 className="intro-title-mobile submitted-request">
                      {localizationService.getLocalizationValue(
                        "submittedRequest",
                        selectedlang
                      )}
                    </h4>
                    <div className="group-name-container-mobile submitted-request">
                      {submittedGroups.map((treatment, tretIndex) => (
                        <button
                          className={
                            "group-name-mobile" +
                            (treatmentToShow &&
                            treatment.TreatmentId ===
                              treatmentToShow.TreatmentId
                              ? " active"
                              : "")
                          }
                          key={tretIndex}
                          onClick={() => showSelectedTreatment(tretIndex)}
                        >
                          {treatment.TreatmentName}
                        </button>
                      ))}
                    </div>
                    <div className="table-box mobile">
                      {treatmentToShow &&
                        treatmentToShow.SingleGroupResponse &&
                        treatmentToShow.SingleGroupResponse.map(
                          (treatment, tretIndex) => (
                            <Table
                              responsive
                              key={tretIndex}
                              className="group-table mobile"
                            >
                              <thead>
                                <tr>
                                  <th className="full-group-name" colSpan="2">
                                    {treatment.FullGroupName}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>
                                    {localizationService.getLocalizationValue(
                                      "group",
                                      selectedlang
                                    )}
                                  </th>

                                  <td>
                                    {treatment ? treatment.GroupName : ""}
                                  </td>
                                </tr>
                                <tr>
                                  {requestType === ClientRequestType.Switch ? (
                                    <>
                                      <th>
                                        {localizationService.getLocalizationValue(
                                          "switchStartDateTime",
                                          selectedlang
                                        )}
                                      </th>
                                    </>
                                  ) : (
                                    <>
                                      <th>
                                        {localizationService.getLocalizationValue(
                                          "startDateTime",
                                          selectedlang
                                        )}
                                      </th>
                                    </>
                                  )}
                                  <td>
                                    {treatment ? treatment.StartDate : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    {localizationService.getLocalizationValue(
                                      "counsellor",
                                      selectedlang
                                    )}
                                  </th>
                                  <td>
                                    {treatment ? treatment.Counsellor : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    {localizationService.getLocalizationValue(
                                      "spaces",
                                      selectedlang
                                    )}
                                  </th>
                                  <td>{treatment ? treatment.Spaces : ""}</td>
                                </tr>
                                {requestType === ClientRequestType.Switch ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <th>
                                        {localizationService.getLocalizationValue(
                                          "sessionHeading",
                                          selectedlang
                                        )}
                                      </th>
                                      <td>
                                        {treatment
                                          ? treatment.NumberOfSessions
                                          : ""}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                <tr>
                                  <th>Status</th>
                                  <td className="status-td">
                                    {treatment.Status === 2 ? (
                                      <span className="badge badge-pill badge-warning">
                                        {localizationService.getLocalizationValue(
                                          "pending",
                                          selectedlang
                                        )}
                                      </span>
                                    ) : treatment.Status === 3 ? (
                                      <span className="badge badge-pill badge-success">
                                        {localizationService.getLocalizationValue(
                                          "approved",
                                          selectedlang
                                        )}
                                      </span>
                                    ) : (
                                      <span class="badge badge-pill badge-danger">
                                        {localizationService.getLocalizationValue(
                                          "rejected",
                                          selectedlang
                                        )}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="main-container-mobile">
                  <div className="stepper-container-mobile">
                    <div className="stepper-wrapper mobile">
                      {groupData.map((groupData, index) => (
                        <div
                          key={index}
                          className={
                            "stepper-item " +
                            (step === groupData.CountId
                              ? "active"
                              : step > groupData.CountId
                              ? "completed"
                              : null)
                          }
                        >
                          <div className="step-counter">
                            {groupData.CountId}
                          </div>
                        </div>
                      ))}
                      <div
                        className={
                          "stepper-item " +
                          (step > groupData.length ? "active" : null)
                        }
                      >
                        <div className="step-counter">
                          {groupData.length + 1}
                        </div>
                      </div>
                    </div>
                    {groupData.map((currentTreat, index) =>
                      step === currentTreat.CountId ? (
                        <div key={index} className="step-name mobile">
                          {currentTreat.TreatmentName}
                        </div>
                      ) : null
                    )}
                    {step > groupData.length ? (
                      <div className="step-name mobile">
                        {localizationService.getLocalizationValue(
                          "reviewAndSubmit",
                          selectedlang
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="content-container-mobile">
                    {requestType === ClientRequestType.Switch ? (
                      <>
                        <h4 className="intro-title-mobile">
                          {step === 1
                            ? localizationService.getLocalizationValue(
                                "programsTitleSwitch",
                                selectedlang
                              )
                            : step === groupData.length + 1
                            ? localizationService.getLocalizationValue(
                                "reviewAndSubmit",
                                selectedlang
                              )
                            : localizationService.getLocalizationValue(
                                "programsTitleSwitch",
                                selectedlang
                              )}
                        </h4>
                      </>
                    ) : (
                      <>
                        <h4 className="intro-title-mobile">
                          {step === 1
                            ? localizationService.getLocalizationValue(
                                "programsTitle",
                                selectedlang
                              )
                            : step === groupData.length + 1
                            ? localizationService.getLocalizationValue(
                                "reviewAndSubmit",
                                selectedlang
                              )
                            : localizationService.getLocalizationValue(
                                "programsTitle",
                                selectedlang
                              )}
                        </h4>
                      </>
                    )}
                    <div className="step-one-intro-box mobile">
                      {step === 1 ? (
                        <>
                          {requestType === ClientRequestType.Switch ? (
                            <>
                              <p className="step-one-intro">
                                {localizationService.getLocalizationValue(
                                  "switchParagraphPart1",
                                  selectedlang
                                )}{" "}
                                <b>{groupData?.[0]?.TreatmentName}</b>.{" "}
                                {localizationService.getLocalizationValue(
                                  "switchParagraphPart2",
                                  selectedlang
                                )}{" "}
                                <b>{startDate}</b>.
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="step-one-intro">
                                {localizationService.getLocalizationValue(
                                  "introParagraphPart1",
                                  selectedlang
                                )}{" "}
                                <b>{endDate}</b>.{" "}
                                {localizationService.getLocalizationValue(
                                  "introParagraphPart2",
                                  selectedlang
                                )}{" "}
                                <b>{startDate}</b>.
                              </p>
                            </>
                          )}
                          <p className="step-one-intro">
                            {localizationService.getLocalizationValue(
                              "introParagraphPart3",
                              selectedlang
                            )}
                          </p>
                          {submittedGroups.length > 1 ? (
                            <p className="step-one-intro">
                              {localizationService.getLocalizationValue(
                                "introParagraphPart4",
                                selectedlang
                              )}
                            </p>
                          ) : null}
                          {requestType === ClientRequestType.Switch ? (
                            <>
                              <br></br>
                              <h4 className="intro-title-mobile">
                                {localizationService.getLocalizationValue(
                                  "selectFirstAvailableWeek",
                                  selectedlang
                                )}
                              </h4>
                              <span>{availableWeeks[currentIndex].value}</span>
                              <br></br>
                              <button
                                style={{ marginRight: 10 }}
                                className="btn-mobile btn-secondary program-color"
                                disabled={isAtStart}
                                onClick={handlePrevious}
                              >
                                {localizationService.getLocalizationValue(
                                  "previous",
                                  selectedlang
                                )}
                              </button>
                              <button
                                style={{ marginLeft: 10 }}
                                className="btn-mobile btn-secondary program-color"
                                disabled={isAtEnd}
                                onClick={handleNext}
                              >
                                {localizationService.getLocalizationValue(
                                  "next",
                                  selectedlang
                                )}
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : step === groupData.length + 1 ? (
                        <>
                          <p className="step-one-intro">
                            {localizationService.getLocalizationValue(
                              "introParagraphPart5",
                              selectedlang
                            )}
                          </p>
                          <p className="step-one-intro">
                            {localizationService.getLocalizationValue(
                              "introParagraphPart6",
                              selectedlang
                            )}
                          </p>
                        </>
                      ) : (
                        <p className="step-one-intro">
                          {localizationService.getLocalizationValue(
                            "introParagraphPart7",
                            selectedlang
                          )}
                        </p>
                      )}
                    </div>
                    {step <= groupData.length ? (
                      <div className="mobile-select-day-container">
                        <h4 className="select-day-title">
                          {" "}
                          {localizationService.getLocalizationValue(
                            "selectADay",
                            selectedlang
                          )}
                        </h4>
                        <div className="day-box">
                          {daysForButtons.map((day) => (
                            <button
                              className={
                                "day-btn" +
                                (selectedDay === day.value ? " active" : "")
                              }
                              key={day.value}
                              onClick={() => filterGroupsByDay(day.value, true)}
                            >
                              {day.text}
                            </button>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    <div className="group-name-container-mobile">
                      {groupData.length > 0
                        ? step <= groupData.length
                          ? [
                              ...new Set(
                                groupData[step - 1].SingleGroupResponse.filter(
                                  (x) => x.StartDateDay === selectedDay
                                ).map((group) => group.GroupName)
                              ),
                            ].map((uniqueGroupName, index) => (
                              <button
                                className={
                                  "group-name-mobile" +
                                  (uniqueGroupName === groupToShow.GroupName
                                    ? " active"
                                    : "")
                                }
                                key={index}
                                onClick={
                                  uniqueGroupName !== groupToShow.GroupName
                                    ? () =>
                                        showGroupInfoByGroupName(
                                          uniqueGroupName
                                        )
                                    : null
                                }
                              >
                                {uniqueGroupName}
                              </button>
                            ))
                          : groupData.map((treatment, tretIndex) => (
                              <button
                                className={
                                  "group-name-mobile" +
                                  (treatmentToShow.length > 0 &&
                                  treatment.TreatmentId ===
                                    treatmentToShow[0].TreatmentId
                                    ? " active"
                                    : "")
                                }
                                key={tretIndex}
                                onClick={() => showSelectedTreatment(tretIndex)}
                              >
                                {treatment.TreatmentName}
                              </button>
                            ))
                        : null}
                    </div>
                    <div className="table-box mobile">
                      {step <= groupData.length ? (
                        groupData[step - 1].SingleGroupResponse.filter(
                          (x) => x.StartDateDay === selectedDay
                        ).length > 0 ? (
                          groupData[step - 1].SingleGroupResponse.filter(
                            (x) =>
                              x.StartDateDay === selectedDay &&
                              x.GroupName === groupToShow.GroupName
                          ).length > 1 ? (
                            <Swiper
                              navigation={true}
                              modules={[Navigation]}
                              className="mySwiper"
                              onSlideChange={handleSlideChange}
                            >
                              {groupData[step - 1].SingleGroupResponse.filter(
                                (x) =>
                                  x.StartDateDay === selectedDay &&
                                  x.GroupName === groupToShow.GroupName
                              ).map((group, index) => (
                                <SwiperSlide key={index}>
                                  <Table
                                    responsive
                                    className="group-table mobile"
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          className="full-group-name"
                                          colSpan="2"
                                        >
                                          {group.FullGroupName}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th>
                                          {requestType ===
                                          ClientRequestType.Switch ? (
                                            <>
                                              {localizationService.getLocalizationValue(
                                                "switchStartDateTime",
                                                selectedlang
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {localizationService.getLocalizationValue(
                                                "startDateTime",
                                                selectedlang
                                              )}
                                            </>
                                          )}
                                        </th>
                                        <td>{group.StartDate}</td>
                                      </tr>
                                      {requestType ===
                                      ClientRequestType.Switch ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <th>
                                            {localizationService.getLocalizationValue(
                                              "endDateTime",
                                              selectedlang
                                            )}
                                          </th>
                                          <td>{group.EndDate}</td>
                                        </tr>
                                      )}
                                      <tr>
                                        <th>
                                          {localizationService.getLocalizationValue(
                                            "counsellor",
                                            selectedlang
                                          )}
                                        </th>
                                        <td>{group.Counsellor}</td>
                                      </tr>
                                      <tr>
                                        <th>
                                          {localizationService.getLocalizationValue(
                                            "spaces",
                                            selectedlang
                                          )}
                                        </th>
                                        <td>{group.Spaces}</td>
                                      </tr>
                                      {requestType ===
                                      ClientRequestType.Switch ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <th>
                                            {localizationService.getLocalizationValue(
                                              "sessionHeading",
                                              selectedlang
                                            )}
                                          </th>
                                          <td>{group.NumberOfSessions}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                  <div className="select-group-box">
                                    {group.Selected ? (
                                      <button
                                        className={
                                          "select-group-btn-mobile active"
                                        }
                                        onClick={() =>
                                          deSelectGroupMobile(group.GroupId)
                                        }
                                      >
                                        {localizationService.getLocalizationValue(
                                          "modalDeselectGroupTitle",
                                          selectedlang
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        className={"select-group-btn-mobile"}
                                        onClick={() =>
                                          selectGroupMobile(group.GroupId)
                                        }
                                      >
                                        {localizationService.getLocalizationValue(
                                          "selectGroup",
                                          selectedlang
                                        )}
                                      </button>
                                    )}
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          ) : (
                            groupData[step - 1].SingleGroupResponse.filter(
                              (x) =>
                                x.StartDateDay === selectedDay &&
                                x.GroupName === groupToShow.GroupName
                            ).map((group, index) => (
                              <Table
                                key={index}
                                responsive
                                className="group-table mobile"
                              >
                                <thead>
                                  <tr>
                                    <th className="full-group-name" colSpan="2">
                                      {group.FullGroupName}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>
                                      {localizationService.getLocalizationValue(
                                        "startDateTime",
                                        selectedlang
                                      )}
                                    </th>
                                    <td>{group.StartDate}</td>
                                  </tr>
                                  {requestType === ClientRequestType.Switch ? (
                                    <></>
                                  ) : (
                                    <tr>
                                      <th>
                                        {localizationService.getLocalizationValue(
                                          "endDateTime",
                                          selectedlang
                                        )}
                                      </th>
                                      <td>{group.EndDate}</td>
                                    </tr>
                                  )}

                                  <tr>
                                    <th>
                                      {localizationService.getLocalizationValue(
                                        "counsellor",
                                        selectedlang
                                      )}
                                    </th>
                                    <td>{group.Counsellor}</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      {localizationService.getLocalizationValue(
                                        "spaces",
                                        selectedlang
                                      )}
                                    </th>
                                    <td>{group.Spaces}</td>
                                  </tr>
                                  {requestType === ClientRequestType.Switch ? (
                                    <></>
                                  ) : (
                                    <tr>
                                      <th>
                                        {localizationService.getLocalizationValue(
                                          "sessionHeading",
                                          selectedlang
                                        )}
                                      </th>
                                      <td>{group.NumberOfSessions}</td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            ))
                          )
                        ) : (
                          <div className="no-groups-on-day-box mobile">
                            <p className="no-groups-on-day mobile">
                              {localizationService.getLocalizationValue(
                                "noGroupsOnDate",
                                selectedlang
                              )}{" "}
                            </p>
                          </div>
                        )
                      ) : treatmentToShow.length > 0 ? (
                        treatmentToShow.map((treatment, tretIndex) => (
                          <Table
                            key={tretIndex}
                            responsive
                            className={"group-table mobile review-table"}
                          >
                            <thead>
                              <tr>
                                <th className="full-group-name" colSpan="2">
                                  {treatment.FullGroupName}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Group</th>
                                <td>{treatment ? treatment.GroupName : ""}</td>
                              </tr>
                              <tr>
                                <th>
                                  {requestType === ClientRequestType.Switch ? (
                                    <>
                                      {localizationService.getLocalizationValue(
                                        "switchStartDateTime",
                                        selectedlang
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {localizationService.getLocalizationValue(
                                        "startDateTime",
                                        selectedlang
                                      )}
                                    </>
                                  )}
                                </th>
                                <td>{treatment ? treatment.StartDate : ""}</td>
                              </tr>
                              {requestType === ClientRequestType.Switch ? (
                                <></>
                              ) : (
                                <>
                                  <tr>
                                    <th>
                                      {localizationService.getLocalizationValue(
                                        "endDateTime",
                                        selectedlang
                                      )}
                                    </th>
                                    <td>
                                      {treatment ? treatment.EndDate : ""}
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <th>
                                  {localizationService.getLocalizationValue(
                                    "counsellor",
                                    selectedlang
                                  )}
                                </th>
                                <td>{treatment ? treatment.Counsellor : ""}</td>
                              </tr>
                              <tr>
                                <th>
                                  {localizationService.getLocalizationValue(
                                    "spaces",
                                    selectedlang
                                  )}
                                </th>
                                <td>{treatment ? treatment.Spaces : ""}</td>
                              </tr>
                              {requestType === ClientRequestType.Switch ? (
                                <></>
                              ) : (
                                <>
                                  <tr>
                                    <th>
                                      {localizationService.getLocalizationValue(
                                        "sessionHeading",
                                        selectedlang
                                      )}
                                    </th>
                                    <td>
                                      {treatment
                                        ? treatment.NumberOfSessions
                                        : ""}
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </Table>
                        ))
                      ) : null}
                    </div>

                    {step <= groupData.length ? (
                      <>
                        {groupData[step - 1].SingleGroupResponse.filter(
                          (x) =>
                            x.StartDateDay == selectedDay &&
                            groupsWithSameName.length <= 1
                        ).length > 0 ? (
                          <>
                            {groupData[step - 1].SingleGroupResponse.filter(
                              (x) =>
                                x.StartDateDay === selectedDay &&
                                x.GroupName === groupToShow.GroupName
                            ).length > 1 ? (
                              <></>
                            ) : (
                              <div className="select-group-box">
                                {groupToShow && groupToShow.Selected ? (
                                  <button
                                    className={"select-group-btn-mobile active"}
                                    onClick={() =>
                                      deSelectGroupMobile(groupToShow.GroupId)
                                    }
                                  >
                                    {localizationService.getLocalizationValue(
                                      "modalDeselectGroupTitle",
                                      selectedlang
                                    )}
                                  </button>
                                ) : (
                                  <button
                                    className={"select-group-btn-mobile"}
                                    onClick={() =>
                                      selectGroupMobile(groupToShow.GroupId)
                                    }
                                  >
                                    {localizationService.getLocalizationValue(
                                      "selectGroup",
                                      selectedlang
                                    )}
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="next-step-box">
                          <div className="back-next-btn-box">
                            <button
                              onClick={decrementStep}
                              disabled={step === 1}
                              className="btn btn-secondary steps-color back-btn"
                            >
                              <i className="fa fa-chevron-left"></i>
                              {localizationService.getLocalizationValue(
                                "onboarding_back_btn",
                                selectedlang
                              )}
                            </button>
                            <button
                              className="btn btn-secondary steps-color next-btn"
                              onClick={incrementStep}
                              disabled={disableGoNext}
                            >
                              {localizationService.getLocalizationValue(
                                "next",
                                selectedlang
                              )}
                              <i className="fa fa-chevron-right"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="next-step-box">
                        <div className="back-submit-btn-box">
                          <button
                            onClick={decrementStep}
                            className="btn back-btn"
                          >
                            <i className="fa fa-chevron-left"></i>
                            {localizationService.getLocalizationValue(
                              "onboarding_back_btn",
                              selectedlang
                            )}
                          </button>
                          <button
                            className="btn submit-btn"
                            onClick={() => Submit()}
                          >
                            {localizationService.getLocalizationValue(
                              "submit",
                              selectedlang
                            )}
                            <i className="fa fa-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : null
        ) : null}
      </React.Fragment>
    </MasterPage>
  );
};
