import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MasterPage from "../master-page";
import { getMaterials } from "../../actions/portal.action";

import localizationService from "../../services/localization-service";

export const MaterialsPage = () => {
  const dispatch = useDispatch();

  const [selectedFolder, setselectedFolder] = useState(null);

  const weeks = useSelector((state) => state.portal.materials);

  const selectedlang = useSelector((state) => state.account.lang);

  useEffect(() => {
    dispatch(getMaterials());
  }, [dispatch]);

  const onClick = (item) => {
    window.open(
      process.env.REACT_APP_API_ROOT.replace("/api/client", "") + item.FileUrl,
      "_blank"
    );
  };

  return (
    <MasterPage>
      <React.Fragment>
        <div id="accordion" className="accordion-container">
          {weeks.MaterialsByWeek &&
            weeks.MaterialsByWeek.map((w, index) => (
              <div className="card" key={index}>
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      onClick={() => setselectedFolder(null)}
                      className={
                        "btn btn-link " + (index !== 0 ? "collapsed" : "")
                      }
                      data-toggle="collapse"
                      data-target={"#collapse" + index}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={"collapse" + index}
                    >
                      {w.WeekName}{" "}
                      <i className="fal fa-chevron-down ico-arrow"></i>
                    </button>
                  </h5>
                </div>
                <div
                  id={"collapse" + index}
                  className={"collapse " + (index === 0 ? "show" : "")}
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    {selectedFolder !== null ? (
                      <div className="materials folder-opened">
                        <button
                          className="btn btn-sm btn-light btn-back-to-materials ico-l"
                          type="button"
                          onClick={() => setselectedFolder(null)}
                        >
                          <i className="fal fa-chevron-left"></i>
                          {localizationService.getLocalizationValue(
                            "backBtn",
                            selectedlang
                          )}
                        </button>
                        <div className="container item-file">
                          {selectedFolder.map((f, numFolder) => (
                            <div className="row" key={numFolder}>
                              <div className="col-sm-9 col-md-9 col-lg-10">
                                <i
                                  className={
                                    "far file-ico " +
                                    (f.Ext === ".pdf"
                                      ? "fa-file-pdf c-wred"
                                      : f.Ext === ".pptx"
                                      ? "fa-file-powerpoint c-red-light"
                                      : f.Ext === ".doc" || f.Ext === ".docx"
                                      ? "fa-file-word c-blue"
                                      : "fa-file-image c-light-green")
                                  }
                                ></i>
                                <span className="file-name">{f.Title}</span>
                                <span className="file-date">
                                  {" "}
                                  {f.CreatedDate}
                                </span>
                                <span className="file-size">
                                  {f.FileSize} Mb
                                </span>
                                <span className="file-type">
                                  {f.Ext === ".pdf"
                                    ? "PDF"
                                    : f.Ext === ".pptx"
                                    ? "PPT"
                                    : f.Ext === ".doc" || f.Ext === ".docx"
                                    ? "Word"
                                    : "Image"}{" "}
                                  Document
                                </span>
                              </div>
                              <div className="col-sm-3 col-md-3 col-lg-2 d-sm-flex align-items-sm-center">
                                <button
                                  className="btn btn-sm btn-primary btn-blue-light btn-download ico-l"
                                  type="button"
                                  onClick={() => onClick(f)}
                                >
                                  <i className="fal fa-arrow-to-bottom"></i>
                                  {localizationService.getLocalizationValue(
                                    "download",
                                    selectedlang
                                  )}
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          "materials " +
                          (w.MaterialsBySession.length > 1 || index === 0
                            ? ""
                            : "folder-opened")
                        }
                      >
                        {w.MaterialsBySession.length > 1 || index === 0 ? (
                          w.MaterialsBySession.map((s, num) => (
                            <button
                              key={num}
                              onClick={() => setselectedFolder(s.MaterialsList)}
                              className="card bg-light"
                            >
                              <div className="card-body text-center">
                                <p className="card-text">{s.FolderName}</p>
                              </div>
                            </button>
                          ))
                        ) : (
                          <div className="container item-file">
                            {w.MaterialsBySession[0].MaterialsList.map(
                              (f, numFolder) => (
                                <div className="row" key={numFolder}>
                                  <div className="col-sm-9 col-md-9 col-lg-10">
                                    <i
                                      className={
                                        "far file-ico " +
                                        (f.Ext === ".pdf"
                                          ? "fa-file-pdf c-wred"
                                          : f.Ext === ".pptx"
                                          ? "fa-file-powerpoint c-red-light"
                                          : f.Ext === ".doc" ||
                                            f.Ext === ".docx"
                                          ? "fa-file-word c-blue"
                                          : "fa-file-image c-light-green")
                                      }
                                    ></i>
                                    <span className="file-name">{f.Title}</span>
                                    <span className="file-date">
                                      {" "}
                                      {f.CreatedDate}
                                    </span>
                                    <span className="file-size">
                                      {f.FileSize} Mb
                                    </span>
                                    <span className="file-type">
                                      {f.Ext === ".pdf"
                                        ? "PDF"
                                        : f.Ext === ".pptx"
                                        ? "PPT"
                                        : f.Ext === ".doc" || f.Ext === ".docx"
                                        ? "Word"
                                        : "Image"}{" "}
                                      Document
                                    </span>
                                  </div>
                                  <div className="col-sm-3 col-md-3 col-lg-2 d-sm-flex align-items-sm-center">
                                    <button
                                      className="btn btn-sm btn-primary btn-blue-light btn-download ico-l"
                                      type="button"
                                      onClick={() => onClick(f)}
                                    >
                                      <i className="fal fa-arrow-to-bottom"></i>
                                      {localizationService.getLocalizationValue(
                                        "download",
                                        selectedlang
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    </MasterPage>
  );
};
