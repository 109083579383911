import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from './auth'

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <AuthContext.Consumer>
            {(authContext) => (
                <Route
                    {...rest}
                    render={props => authContext.isAuth()
                        ? (<Component {...props} />)
                        : (<Redirect to="/login" />)
                    }
                />
            )}
        </AuthContext.Consumer>
    );
}
export default PrivateRoute;