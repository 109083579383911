import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

const initialState = {}
const enhancers = [];

const middleware = [
    thunk,
    routerMiddleware(history)
]

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
)

export default store