import { http } from "../framework/api";
import config from "../config";

import {
  GET_MATERIALS_LOADING,
  GET_MATERIALS_LOADING_SUCCESSFUL,
  GET_MATERIALS_LOADING_FAIL,
  GET_SESSIONS_LOADING,
  GET_SESSIONS_LOADING_SUCCESSFUL,
  GET_SESSIONS_LOADING_FAIL,
  GET_REQUEST_LOADING,
  GET_REQUEST_LOADING_SUCCESSFUL,
  GET_REQUEST_LOADING_FAIL,
  GET_SUBMITTED_LOADING,
  GET_SUBMITTED_LOADING_SUCCESSFUL,
  GET_SUBMITTED_LOADING_FAIL,
  SEND_REQUEST_LOADING,
  SEND_REQUEST_LOADING_SUCCESSFUL,
  SEND_REQUEST_LOADING_FAIL,
  TIME_ZONE_MODAL_OPEN,
  TIME_ZONE_MODAL_CLOSE,
  REQUEST_MODAL_OPEN,
  REQUEST_MODAL_CLOSE,
} from "../reducers/portal.reducer";

export const getSessions = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_SESSIONS_LOADING,
      payload: [],
    });
    let url = `${config.API_ROOT_URL}/sessions/my`;

    http("GET", url, null)
      .then(function (res) {
        dispatch({
          type: GET_SESSIONS_LOADING_SUCCESSFUL,
          payload: res,
        });
        resolve(res);
      })
      .catch(function (res) {
        dispatch({
          type: GET_SESSIONS_LOADING_FAIL,
          payload: res,
        });
        reject(res);
      });
  });

export const getMaterials = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_MATERIALS_LOADING,
      payload: [],
    });
    let url = `${config.API_ROOT_URL}/materials/my`;

    http("GET", url, null)
      .then(function (res) {
        dispatch({
          type: GET_MATERIALS_LOADING_SUCCESSFUL,
          payload: res,
        });
        resolve(res);
      })
      .catch(function (res) {
        dispatch({
          type: GET_MATERIALS_LOADING_FAIL,
          payload: res,
        });
        reject(res);
      });
  });

export const getRequest = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_REQUEST_LOADING,
      payload: [],
    });
    let url = `${config.API_ROOT_URL}/clientRequest/clientReviewSingleResponse`;

    http("GET", url, null)
      .then(function (res) {
        dispatch({
          type: GET_REQUEST_LOADING_SUCCESSFUL,
          payload: res,
        });
        // console.log("success")
        //console.log(res);
        resolve(res);
      })
      .catch(function (res) {
        dispatch({
          type: GET_REQUEST_LOADING_FAIL,
          payload: res,
        });
        reject(res);
      });
  });

export const getSubmitted = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_SUBMITTED_LOADING,
      payload: [],
    });
    let url = `${config.API_ROOT_URL}/clientRequest/clientViewSubmittedResponse`;

    http("GET", url, null)
      .then(function (res) {
        dispatch({
          type: GET_SUBMITTED_LOADING_SUCCESSFUL,
          payload: res,
        });
        resolve(res);
      })
      .catch(function (res) {
        dispatch({
          type: GET_SUBMITTED_LOADING_FAIL,
          payload: res,
        });
        reject(res);
      });
  });

export const sendRequest = (selectedGroups) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: SEND_REQUEST_LOADING,
      payload: [],
    });
    let url = `${config.API_ROOT_URL}/clientRequest/submitClientResponse`;
    let data = { selectedGroups: selectedGroups };

    http("PUT", url, data)
      .then(function (res) {
        dispatch({
          type: SEND_REQUEST_LOADING_SUCCESSFUL,
          payload: res,
        });
        resolve(res);
      })
      .catch(function (res) {
        dispatch({
          type: SEND_REQUEST_LOADING_FAIL,
          payload: res,
        });
        reject(res);
      });
  });

export const openRequestModal = () => (dispatch) => {
  dispatch({
    type: REQUEST_MODAL_OPEN,
    payload: true,
  });
};

export const closeRequestModal = () => (dispatch) => {
  dispatch({
    type: REQUEST_MODAL_CLOSE,
    payload: false,
  });
};
export const checkGroupAvailabilityMultiple =
  (startWeek, treatmentId, enrolledAccountGroupId) => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: GET_REQUEST_LOADING,
        payload: [],
      });
      let url = `${config.API_ROOT_URL}/clientRequest/checkgroupavailabilitymultiple?treatmentId=${treatmentId}&startWeek=${startWeek}&enrolledAccountGroupId=${enrolledAccountGroupId}`;
      http("GET", url, null)
        .then(function (res) {
          dispatch({
            type: GET_REQUEST_LOADING_SUCCESSFUL,
            payload: res,
          });
          resolve(res);
        })
        .catch(function (res) {
          dispatch({
            type: GET_REQUEST_LOADING_FAIL,
            payload: res,
          });
          reject(res);
        });
    });
