import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MasterPage from "./master-page";
import { getSessions } from "../actions/portal.action";
import { postTimeZone } from "../actions/account.action";
import storageService from "../services/storage-service";
import notification from "../services/notification-service";
import localizationService from "../services/localization-service";
import config from "../config";
import { Oval } from "react-loader-spinner";

export const DashboardPage = () => {
  const [currentTimeZone, setCurrentTimeZone] = useState(null);
  const [timeZoneSelectedId, setTimeZoneSelectedId] = useState(null);
  const [timeZoneSet, setTimeZoneSet] = useState(false);

  const dispatch = useDispatch();
  const weeks = useSelector((state) => state.portal.sessions);
  const selectedlang = useSelector((state) => state.account.lang);
  const timeZones = useSelector((state) => state.account.timeZones);
  const sessionsLoading = useSelector((state) => state.portal.sessionsLoading);
  const [showTimeZoneValidationMsg, setshowTimeZoneValidationMsg] =
    useState(false);

  useEffect(() => {
    dispatch(getSessions());
  }, []);

  useEffect(() => {
    var ctz = storageService.getStorageItem(config.TIMEZONE);
    if (ctz != null) {
      setCurrentTimeZone(ctz);
      setTimeZoneSet(true);
    }
  }, [currentTimeZone]);

  const changeDropDown = (timeZoneId) => {
    setTimeZoneSelectedId(timeZoneId);
    setshowTimeZoneValidationMsg(false);

  };

  const clickUpdateTimeZone = () => {
    if (timeZoneSelectedId == null) {
      setshowTimeZoneValidationMsg(true);
    } else {
      dispatch(postTimeZone(timeZoneSelectedId))
        .then(function (res) {
          setCurrentTimeZone(timeZoneSelectedId);
          notification.success(
            localizationService.getLocalizationValue(
              "timezoneUpdatedSuccess",
              selectedlang
            )
          );
          dispatch(getSessions());
        })
        .catch(function (res) {});
    }
  };

  const onClick = (item) => {
    window.open(item.ZoomUrl, "_blank");
  };

  return (
    <MasterPage>
      <React.Fragment>
        <div
          className="overlay"
          style={sessionsLoading === false ? { display: "none" } : {}}
        >
          <Oval
            display="block"
            marginLeft="auto"
            marginRight="auto"
            color="#00BFFF"
            secondaryColor="#99e6ff"
            height={100}
            width={100}
            visible={sessionsLoading}
            ariaLabel="loading-indicator"
          />
        </div>
        {/* Time zone dropdown */}

        <div className="alert alert-secondary" role="alert" style={{}}>
          <p>
            {currentTimeZone !== null
              ? localizationService.getLocalizationValue(
                  "currentTimeZone",
                  selectedlang
                ) +
                ": " +
                timeZones.find((x) => x.id == currentTimeZone).name
              : localizationService.getLocalizationValue(
                  "timeZoneNotSet",
                  selectedlang
                )}
          </p>
          <div className="btn-group">
            <button
              className="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {timeZoneSelectedId == null
                ? localizationService.getLocalizationValue(
                    "selectTimeZone",
                    selectedlang
                  )
                : timeZones.find((x) => x.id === timeZoneSelectedId).name}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {timeZones.map((tz, tzIndex) => (
                <button
                  className="dropdown-item"
                  key={tzIndex}
                  onClick={() => changeDropDown(tz.id)}
                  href=""
                >
                  {tz.name}
                </button>
              ))}
            </div>
          </div>
          <button
            onClick={() => clickUpdateTimeZone()}
            type="button"
            className="btn btn-sm  btn-secondary"
            style={{ float: "", marginLeft: "7px" }}
          >
            {localizationService.getLocalizationValue(
              "updateTimeZone",
              selectedlang
            )}
          </button>
        </div>
        {showTimeZoneValidationMsg ? (
          <p className="time-zone-validation-msg">
            {localizationService.getLocalizationValue(
              "timeZoneValidationMsg",
              selectedlang
            )}
          </p>
        ) : null}

        {weeks.length === 0 ? (
          <div className="alert alert-warning">
            {localizationService.getLocalizationValue(
              "noOnlineSession",
              selectedlang
            )}
          </div>
        ) : (
          <div
            id="accordion"
            className="accordion-container"
            style={timeZoneSet === false ? { display: "none" } : {}}
          >
            {weeks.map((w, index) => (
              <div className="card" key={index}>
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className={
                        "btn btn-link " + (index !== 0 ? "collapsed" : "")
                      }
                      data-toggle="collapse"
                      data-target={"#collapse" + index}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={"collapse" + index}
                    >
                      {w.WeekTitle}{" "}
                      <i className="fas fa-chevron-down ico-arrow"></i>
                    </button>
                  </h5>
                </div>

                <div
                  id={"collapse" + index}
                  className={"collapse " + (index === 0 ? "show" : "")}
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  {w.Dates.map((s, sessionNum) => (
                    <div className="card-body" key={sessionNum}>
                      <div className="session">
                        <div className="row pos-st-child-minw768">
                          <div className="col-md-6">
                            <i
                              className={
                                s.IsDisabled === true
                                  ? "fas fa-ban ico-session"
                                  : "fas fa-check ico-session"
                              }
                            ></i>
                            <div
                              className={
                                s.IsDisabled === true ? "cancelled" : ""
                              }
                            >
                              <h5>
                                {localizationService.getLocalizationValue(
                                  "sessionTitle",
                                  selectedlang
                                )}
                                : {s.SessionName}
                              </h5>
                              <div className="date-time">
                                <label>
                                  {localizationService.getLocalizationValue(
                                    "counsellor",
                                    selectedlang
                                  )}
                                  :
                                </label>{" "}
                                <span className="d-inline-block">
                                  {s.Councellor}
                                </span>
                              </div>
                              <div className="date-time">
                                <label>
                                  {localizationService.getLocalizationValue(
                                    "date",
                                    selectedlang
                                  )}
                                  :
                                </label>{" "}
                                <span className="d-ib">{s.Date}</span>
                              </div>
                              <div className="date-time">
                                <label>
                                  {localizationService.getLocalizationValue(
                                    "time",
                                    selectedlang
                                  )}
                                  :
                                </label>{" "}
                                <span className="d-ib">{s.Time}</span>
                              </div>
                              <div
                                className={
                                  "meeting-passcode " +
                                  (s.ZoomUrl === "" ? "invisible" : "")
                                }
                              >
                                <label>Passcode:</label>
                                <span className="d-ib">{s.ZoomPassword}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 bg-gradient-warning">
                            <p
                              className={
                                s.IsDisabled === true ? "" : "invisible"
                              }
                            >
                              {localizationService.getLocalizationValue(
                                "sessionCancelled",
                                selectedlang
                              )}
                            </p>
                          </div>
                          <div className="col-md-3 d-flex align-items-md-center mt-3 mt-md-0">
                            <button
                              onClick={() => onClick(s)}
                              className={
                                "btn btn-lg btn-primary btn-blue-light btn-block " +
                                (s.ZoomUrl === "" ? "invisible" : "")
                              }
                              type="button"
                            >
                              {localizationService.getLocalizationValue(
                                "joinSession",
                                selectedlang
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    </MasterPage>
  );
};
