// import AsyncStorage from '@react-native-async-storage/async-storage';

let userLang = null;

export default {
  getStorageItem: (key) => {
    try {
      const value = sessionStorage.getItem(key);

      if (value !== null && value !== "null") {
        return value;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
  setStorageItem: (key, value) => {
    try {
      sessionStorage.setItem(key, value.toString());
    } catch (error) {}
  },

  removeStorageItem: async (key, value) => {
    try {
      await sessionStorage.setItem(key, value.toString());
    } catch (error) {}
  },

  getUserLocale: function () {
    //return "fr-CA";
    if (userLang == null) {
      return "en-CA";
    } else {
      return userLang;
    }
  },
  setUserLocale: function (language) {
    userLang = language;
  },
};
