export const Localizations = {
  // Login Page
  username: {
    en: "Username",
    fr: "Nom d’utilisateur",
  },
  password: {
    en: "Password",
    fr: "Mot de passe",
  },
  wrongCredentialsFirstPart: {
    en: "The username and password combination",
    fr: "La combinaison nom d'utilisateur et mot de passe",
  },
  wrongCredentialsSecondPart: {
    en: "could not be found.",
    fr: "n'a pas pu être trouvée",
  },
  accountDisabled: {
    en: "Your account has been disabled.",
    fr: "Votre compte a été désactivé.",
  },
  usernameRequired: {
    en: "Username is required",
    fr: "Le nom d’utilisateur est requis",
  },
  passwordRequired: {
    en: "Password is required",
    fr: "Mot de passe requis",
  },
  login: {
    en: "Login",
    fr: "Connexion",
  },
  forgotPasswordBtn: {
    en: "Forgot password",
    fr: "Mot de passe oublié",
  },
  createAccBtn: {
    en: "Create Account",
    fr: "Créer un Compte",
  },
  help: {
    en: "Help",
    fr: "Aidez-moi",
  },
  newPassword: {
    en: "New Password",
    fr: "Nouveau mot de passe",
  },
  confirmPassword: {
    en: "Confirm Password",
    fr: "Confirmez le mot de passe",
  },
  notValidPassword: {
    en: "Password is not valid",
    fr: "Le mot de passe n’est pas valide",
  },
  validationMainText: {
    en: "Your password must contain:",
    fr: "Votre mot de passe doit contenir",
  },
  validationLength: {
    en: "8 - 16 characters",
    fr: "8 - 16 caractères",
  },
  validationUpperAndLowerCase: {
    en: "At least 1 uppercase letter & 1 lowercase letter",
    fr: "Au moins une lettre majuscule et une lettre minuscule",
  },
  validationNumberAndSymbol: {
    en: "At least 1 number and 1 symbol",
    fr: "Au moins 1 chiffre et 1 symbole",
  },
  passAndConfirmPassDontMatch: {
    en: "Password and confirmed password don't match.",
    fr: "Le mot de passe et le mot de passe confirmé ne correspondent pas.",
  },
  //
  // Forgot Password Page
  forgotPasswordQuestion: {
    en: "Forgot your password?",
    fr: "Mot de passe oublié?",
  },
  sendPasswordBtn: {
    en: "Send Password",
    fr: "Envoyer mot de passe",
  },
  successResetEmailFirst: {
    en: "An email, with reset instructions, has been sent to your",
    fr: "Un e-mail, avec des instructions de réinitialisation,",
  },
  successResetEmailSecond: {
    en: "Email address",
    fr: "A été envoyé à votre adresse e-mail",
  },
  backToLoginBtn: {
    en: "Back to login",
    fr: "Retour connexion",
  },

  // AuthLoadingScreen

  mainLoadingTitle: {
    en: "Loading...",
    fr: "Chargement en cours...",
  },
  mainLoadingSubTitle: {
    en: "Wagon",
    fr: "Wagon",
  },

  // CommitmentsList

  commitmentsHeaderTitle: {
    en: "My Values",
    fr: "Mes Valeurs",
  },

  // CopeList

  copingStrategiesHeaderTitle: {
    en: "My Coping Strategies",
    fr: "Mes stratégies de gestion des émotions",
  },
  copingStrategiesAddBtn: {
    en: "Add Strategies",
    fr: "",
  },

  // DailyMessage

  dailyMessageHeaderTitle: {
    en: "Daily Message",
    fr: "Message Quotidien",
  },

  // DailyReview

  dailyReviewHeaderTitle: {
    en: "Daily Review",
    fr: "Évaluation Quotidienne",
  },

  // DailyReviewByProgram

  dailyReviewNewHeaderTitle: {
    en: "Daily Check-In",
    fr: "Contrôle quotidien",
  },
  dailyReviewNewSummaryHeaderTitle: {
    en: "Daily Check-In Summary",
    fr: "Résumé du contrôle quotidien",
  },

  // DailyReviewFinish

  dailyReviewFinishHeaderTitle: {
    en: "Daily Check-In Summary",
    fr: "Sommaire de l’évaluation quotidienne",
  },
  dailyReviewFinishTitle1: {
    en: "You've completed your",
    fr: "Félicitations! Vous avez terminé",
  },
  dailyReviewFinishTitle2: {
    en: "Daily Check-In for",
    fr: "votre évaluation quotidienne du",
  },

  // DailyReviewSummary

  dailyReviewSummaryBtnTitle: {
    en: "Submit",
    fr: "Soumettre",
  },

  // GoalReminders

  goalRemindersHeaderTitle: {
    en: "Reminders",
    fr: "Rappels",
  },
  goalRemindersDailyTitle: {
    en: "Daily",
    fr: "Tous les jours",
  },
  goalRemindersDailyTitleChanged: {
    en: "Recur Daily",
    fr: "Répéter quotidiennement",
  },
  goalRemindersThisWeekTitle: {
    en: "This Week",
    fr: "Cette semaine",
  },
  goalRemindersThisWeekChanged: {
    en: "Recur Weekly",
    fr: "Répéter chaque semaine",
  },
  goalRemindersThisMonthTitle: {
    en: "This Month",
    fr: "Ce mois-ci",
  },
  goalRemindersThisMonthTitleChanged: {
    en: "Recur Monthly",
    fr: "Répéter mensuel",
  },
  goalRemindersOneTimeTitle: {
    en: "One-Time",
    fr: "Une fois",
  },

  // GratitudeList

  gratitudeListHeaderTitle: {
    en: "Gratitude List",
    fr: "Liste Des Raisons D’être Reconnaissant",
  },
  gratitudeListTitle: {
    en: "I'm grateful for...",
    fr: "Je suis reconnaissant(e) de...",
  },
  gratitudeAddGratitudeBtn: {
    en: "Add Gratitude",
    fr: "",
  },

  // JournalList

  journalListHeaderTitle: {
    en: "Journal",
    fr: "Journal",
  },
  journalListAddNewBtn: {
    en: "Add New",
    fr: "Аjouter un nouveau",
  },

  // MyPlan

  myPlanHeaderTitle: {
    en: "My Plan",
    fr: "Mon Plan",
  },
  myPlanAbstinentTitle: {
    en: "I've been abstinent for",
    fr: "J'ai été abstinent pour",
  },
  myPlanAbstinentCounterYears: {
    en: "YEARS",
    fr: "ANNÉE(S)",
  },
  myPlanAbstinentCounterMonths: {
    en: "MONTHS",
    fr: "MOIS",
  },
  myPlanAbstinentCounterDays: {
    en: "DAYS",
    fr: "JOUR(S)",
  },
  myPlanGoalsTab: {
    en: "Goals",
    fr: "Buts",
  },
  myPlanCompletedGoalsTab: {
    en: "Completed Goals",
    fr: "Buts Complétés",
  },
  myPlanGoalsTodayTitle: {
    en: "Today",
    fr: "Aujourd’hui",
  },
  myPlanGoalsThisWeekTitle: {
    en: "This Week (M-S)",
    fr: "Cette semaine (L-D)",
  },
  myPlanGoalsThisWeekText: {
    en: "This Week",
    fr: "Cette semaine",
  },
  myPlanGoalsThisMonthTitle: {
    en: "This Month",
    fr: "Ce mois-ci",
  },
  myPlanGoalsOneTimeTitle: {
    en: "One Time Goals",
    fr: "Buts une fois",
  },
  myPlanSetReminderBtn: {
    en: "Set Reminder",
    fr: "Activer un rappel",
  },
  myPlanReminderSetBtn: {
    en: "Reminder Set",
    fr: "Rappel activé",
  },
  myPlanAbstinentPopupOkBtn: {
    en: "OK",
    fr: "OK",
  },
  myPlanAbstinentPopupFirstParthFirstRow: {
    en: "You've been abstinent for ",
    fr: "Vous avez été abstinent pendant ",
  },
  myPlanAbstinentPopupSecondParthFirstRowSingular: {
    en: "month!",
    fr: "mois!",
  },
  myPlanAbstinentPopupSecondParthFirstRowPlural: {
    en: "months!",
    fr: "mois!",
  },
  myPlanAbstinentPopupSecondParthFirstRowYear: {
    en: "year!",
    fr: "ANNÉE!",
  },
  myPlanAbstinentPopupSecondRow1: {
    en: "This is a difficult first milestone in recovery, but you did it!\r\nKeep up the good work and enjoy the journey…",
    fr: "C’est une première étape difficile à franchir, mais vous avez réussi!\r\nContinuez ainsi, et profitez de l’aventure...",
  },
  myPlanAbstinentPopupSecondRow2: {
    en: "You’ve just achieved another big milestone in early recovery.\r\nWell done!",
    fr: "Vous venez de franchir une nouvelle étape majeure dans votre processus de rétablissement.\r\nBien joué!",
  },
  myPlanAbstinentPopupSecondRow3: {
    en: "Share this big achievement with friends and family supporting your recovery!",
    fr: "Partagez cette belle réussite avec vos amis et votre famille qui vous soutiennent dans votre rétablissement.",
  },
  myPlanAbstinentPopupSecondRow4: {
    en: "You’re really working hard in recovery, one day at a time.\r\nWay to go!",
    fr: " Vous travaillez très dur pour votre rétablissement, un jour à la fois.\rBravo!",
  },
  myPlanAbstinentPopupSecondRow5: {
    en: "Remember that recovery gets easier over time and you’re getting there!\r\nCongrats!",
    fr: "Rappelez-vous : le rétablissement devient plus facile à mesure que l’on progresse; vous y êtes presque!\r\nFélicitations!",
  },
  myPlanAbstinentPopupSecondRow6: {
    en: "This is a huge accomplishment!\r\nRemember to pick up a chip and be proud.",
    fr: "C’est une réussite énorme.\r\n N’oubliez pas votre médaille, et soyez fier (ère)!",
  },
  myPlanAbstinentPopupSecondRow7: {
    en: "You’re really taking your recovery seriously, and it’s showing!\r\nWell done!",
    fr: "Vous prenez votre rétablissement très au sérieux, ça se voit.\r\nBien joué!",
  },
  myPlanAbstinentPopupSecondRow8: {
    en: "Staying abstinent helps keep you in the present moment.\r\nEnjoy this moment of accomplishment!",
    fr: "En restant abstinent(e), vous profitez du moment présent. Profitez de ce moment de réussite!",
  },
  myPlanAbstinentPopupSecondRow9: {
    en: "You’ve just achieved another incredible milestone on your recovery journey.\r\nTake the time to pat yourself on the back. You deserve it!",
    fr: "Vous venez d’accomplir une autre étape majeure dans votre processus de rétablissement.\r\nPrends le temps de vous féliciter. Vous le méritez!",
  },
  myPlanAbstinentPopupSecondRow10: {
    en: "Be proud and grateful for another month of abstinence.\r\nKeep up the great work!",
    fr: "Soyez fier (ère) et reconnaissant(e) pour ce mois d’abstinence supplémentaire.\r\nContinuez ainsi!",
  },
  myPlanAbstinentPopupSecondRow11: {
    en: "You’ve come a long way on your journey!\r\nTake a moment to look back at your incredible progress.",
    fr: "Vous avez fait un chemin incroyable depuis que vous avez commencé!\r\nPrenez le temps de revenir sur votre incroyable progression.",
  },
  myPlanAbstinentPopupSecondRow12: {
    en: "This is a very special day for you.\r\nCelebrate with those who love and support you. Enjoy your day!",
    fr: "C’est une journée très spéciale pour vous. Célébrez avec ceux qui vous sont chers et vous soutiennent. Profitez de votre journée!",
  },
  // myPlanAbstinentPopupThirdRow: {
  //     en: "one day at a time.",
  //     fr: "un jour à la fois."
  // },
  // myPlanAbstinentPopupFourthRow: {
  //     en: "Way to go!",
  //     fr: "Chemin à parcourir!"
  // },
  myPlanPopupCompletedDailyGoalsTitle: {
    en: "Congratulations!",
    fr: "Félicitations!",
  },

  myPlanPopupCompletedGoalsForTheGirstTime: {
    en: "You’ve completed all of your goals for the first time! You’re off to a great start!",
    fr: "Vous avez atteint tous vos objectifs pour la première fois! Vous êtes très bien parti(e)!",
  },
  myPlanPopupCompletedDailyGoals: {
    en: "You’ve completed all of your goals today! Keep up the great work!",
    fr: "Vous avez atteint tous vos objectifs de la journée! Continuez ainsi!",
  },
  myPlanPopupCompletedGoalsForThreeConsecutiveDays: {
    en: "You’ve completed all of your goals for 3 consecutive days! Great job!",
    fr: "Vous avez atteint vos objectifs pendant 3 jours de suite! Joli travail!",
  },
  myPlanPopupCompletedGoalsForSevenConsecutiveDays: {
    en: "You’ve completed all of your goals for 7 consecutive days! Well done!",
    fr: "Vous avez atteint vos objectifs pendant 7 jours de suite! Bien joué!",
  },
  myPlanPopupCompletedGoalsForFourteenConsecutiveDays: {
    en: "You’ve completed all of your goals for 14 consecutive days! Fantastic job!",
    fr: "Vous avez atteint vos objectifs pendant 14 jours de suite! Super travail!",
  },
  myPlanPopupCompletedGoalsForThirtyConsecutiveDays: {
    en: "You’ve completed all of your goals for 30 consecutive days! Amazing! You’re really taking your recovery plan seriously!",
    fr: "Vous avez atteint vos objectifs pendant 30 jours de suite! Incroyable! Vous prenez vraiment votre programme de rétablissement au sérieux!",
  },
  myPlanPopupAtendedPlannedMettings: {
    en: "You’ve attended all of your planned meetings for 2 consecutive weeks! Meetings help to keep you honest about your recovery. They also help others by you being there and sharing.",
    fr: "Vous avez assisté à toutes les réunions prévues des 2 dernières semaines! Les réunions vous aident à être honnête au sujet de votre rétablissement. Votre présence aide également les autres, qui vous écoutent partager vos impressions.",
  },
  myPlanPopupCompletedDailyGoalsOkBtn: {
    en: "OK",
    fr: "OK",
  },

  // CreateCopeItem

  createCopeItemCancelBtn: {
    en: "Cancel",
    fr: "Annuler",
  },
  createCopeItemSaveBtn: {
    en: "Save",
    fr: "Enregistrer",
  },
  createCopeItemShareBtn: {
    en: "Share",
    fr: "Partager",
  },

  // CreateGratitudeItem

  createGratitudeItemCancelBtn: {
    en: "Cancel",
    fr: "Annuler",
  },
  createGratitudeItemSaveBtn: {
    en: "Save",
    fr: "Enregistrer",
  },
  createGratitudeItemShareBtn: {
    en: "Share",
    fr: "Partager",
  },

  // CreateJournalItem

  createJournalheaderTitle: {
    en: "Journal",
    fr: "Journal",
  },
  createJournalItemCancelBtn: {
    en: "Cancel",
    fr: "Annuler",
  },
  createJournalItemSaveBtn: {
    en: "Save",
    fr: "Enregistrer",
  },
  createJournalItemShareBtn: {
    en: "Share",
    fr: "Partager",
  },

  // CreateSosItem

  createSosItemheaderTitle: {
    en: "Add Contact",
    fr: "Ajouter une personne",
  },
  createSosItemPlaceholderContactName: {
    en: "Contact Name",
    fr: "Nom de la personne",
  },
  createSosItemPlaceholderContactNumber: {
    en: "Contact Number",
    fr: "Numéro de la personne",
  },
  createSosItemCancelBtn: {
    en: "Cancel",
    fr: "Annuler",
  },
  createSosItemSaveBtn: {
    en: "Save",
    fr: "Enregistrer",
  },

  // DailyReviewNonAbstinentQuestion

  dailyReviewNonAbstinentStruggilingQuestion: {
    en: "Are you struggling?",
    fr: "Êtes-vous en difficulté?",
  },
  dailyReviewNonAbstinentSupportGroup: {
    en: "Your support group understands - they want to help you!",
    fr: "Votre groupe de soutien comprend - il veut vous aider!",
  },
  dailyReviewNonAbstinentSubQuestion: {
    en: "You've indicated that you were not abstinent today. Is this correct?",
    fr: "Vous avez indiqué que vous n'étiez pas abstinent aujourd'hui. Est-ce correct?",
  },
  dailyReviewNonAbstinentQuestionNoBtn: {
    en: "NO",
    fr: "NON",
  },
  dailyReviewNonAbstinentQuestionYesBtn: {
    en: "YES",
    fr: "OUI",
  },
  dailyReviewNonAbstinentQuestionSOSNumbers: {
    en: "SOS Numbers",
    fr: "SOS", // Marija
  },

  // Loader

  loaderTitle: {
    en: "Loading...",
    fr: "Chargement en cours...",
  },

  // SingleCompletedGoal

  singleCompletedGoalTitle: {
    en: "Completed",
    fr: "Terminé",
  },

  // SingleGoal

  singleGoalRemaningTitle: {
    en: "remaining",
    fr: "reste",
  },

  // SingleDailyReviewQuestion

  singleDailyReviewQuestionOkBtn: {
    en: "OK",
    fr: "OK",
  },

  // Progress

  progressHeaderTitle: {
    en: "Progress",
    fr: "Progression",
  },
  progressGoalsTab: {
    en: "Goals",
    fr: "Buts",
  },
  progressDailyReviewTab: {
    en: "Daily Check-In",
    fr: "Contrôle quotidien",
  },
  progressCurrentWeekBtn: {
    en: "Current Week",
    fr: "Cette semaine",
  },
  progressLastWeekBtn: {
    en: "Last Week",
    fr: "La semaine dernière",
  },
  progressLastMonthBtn: {
    en: "Last Month",
    fr: "Le mois dernier",
  },
  progressAllTimeBtn: {
    en: "All Time",
    fr: "Tout le temps",
  },
  progressAverageTitle: {
    en: "Total",
    fr: "Atteinte total",
  },
  progressGoalCompletionTitle: {
    en: "Goal Completion",
    fr: "des objectifs",
  },
  progressFromTitle: {
    en: "From:",
    fr: "De:",
  },
  progressToTitle: {
    en: "To:",
    fr: "À:",
  },
  progressTriggeredTitle: {
    en: "Triggered",
    fr: "Déclenché",
  },
  progressComfortableTitle: {
    en: "Comfortable",
    fr: "Confortable",
  },
  progressUncomfortableTitle: {
    en: "Uncomfortable",
    fr: "Mal à l’aise",
  },
  progressResentfulTitle: {
    en: "Resentful",
    fr: "Sentiment de rancune",
  },
  progressHonestTitle: {
    en: "Honest",
    fr: "Honnête avec mes sentiments",
  },
  progressIsolatedTitle: {
    en: "Isolated",
    fr: "Vous êtes-vous isolé",
  },
  progressAdheredToCommitmentsTitle: {
    en: "Adhered to Commitments",
    fr: "Respecté les engagements",
  },

  // ResetPassword

  resetPasswordTitle: {
    en: "Reset Password",
    fr: "Réinitialiser le mot de passe",
  },
  resetPasswordSubmitBtn: {
    en: "Submit",
    fr: "Soumettre",
  },
  setPasswordTitle: {
    en: "Set Password",
    fr: "Définir le mot de passe",
  },

  // ResourceList

  resourceHeaderTitle: {
    en: "Resources",
    fr: "Ressources",
  },
  resourceWagonSupportTitle: {
    en: "Wagon Support:",
    fr: "Soutien Wagon:",
  },
  resourceWebsiteTitle: {
    en: "Website:",
    fr: "Sites web:",
  },
  resourceProvincialHelplinesTitle: {
    en: "Provincial Helplines:",
    fr: "Lignes d'assistance provinciales:",
  },

  // SerenityScreen

  serenityScreenHeaderTitle: {
    en: "Serenity Prayer",
    fr: "Prière de la Sérénité",
  },

  // SingleGoalReminders

  singleGoalRemindersHeaderTitle: {
    en: "Reminders for:",
    fr: "Rappels pour:",
  },
  addReminderBtn: {
    en: "Add Reminder",
    fr: "Active un rappel",
  },
  singleGoalRemindersSetReminderTitle: {
    en: "Set Reminder",
    fr: "Activer un rappel",
  },
  singleGoalRemindersOneTimeBtn: {
    en: "One Time",
    fr: "Une fois",
  },
  singleGoalRemindersRecurDailyBtn: {
    en: "Recur Daily",
    fr: "Répéter quotidiennement",
  },
  singleGoalRemindersRecurWeeklyBtn: {
    en: "Recur Weekly",
    fr: "Répéter chaque semaine",
  },
  singleGoalRemindersRecurMonthlyBtn: {
    en: "Recur Monthly",
    fr: "Répéter mensuel",
  },

  // SosScreen

  sosScreenHeaderTitle: {
    en: "SOS",
    fr: "SOS",
  },
  sosScreenTitle: {
    en: "You're not Alone.",
    fr: "Vous n’êtes pas seul.",
  },
  sosScreenFirstTabFirstRow: {
    en: "Coping",
    fr: "Stratégies",
  },
  sosScreenFirstTabSecondRow: {
    en: "Strategies",
    fr: "d’adaptation",
  },
  sosScreenSecondTabFirstRow: {
    en: "Gratitude",
    fr: "Liste des raisons ",
  },
  sosScreenSecondTabSecondRow: {
    en: "List",
    fr: "d’être reconnaissant",
  },
  sosScreenThirdTabFirstRow: {
    en: "Serenity",
    fr: "Prière de la",
  },
  sosScreenThirdTabSecondRow: {
    en: "Prayer",
    fr: "Sérénité",
  },
  sosScreenAddContactBtn: {
    en: "Add Contact",
    fr: "Ajouter une personne",
  },

  // Tools

  toolsHeaderTitle: {
    en: "Tools",
    fr: "Outils",
  },
  toolsGuidedPractise: {
    en: "Guided Practice",
    fr: "Pratique guidée",
  },
  toolsUnstickMyThoughts: {
    en: "Unstick My Thoughts",
    fr: "Décoller mes pensées",
  },
  toolsGroundMyself: {
    en: "Ground Myself",
    fr: "M’ancrer",
  },
  toolsCopingStrategies: {
    en: "Use My Coping Strategies",
    fr: "Utiliser mes stratégies de gestion des émotions",
  },
  toolsGetHelp: {
    en: "Get Help",
    fr: "Obtenir de l’aide",
  },

  // GuidedPractise

  guidedPractiseTitle: {
    en: "Guided Practice",
    fr: "Pratique guidée",
  },
  guidedPractiseExerciseTxt: {
    en: "Exercise",
    fr: "Exercice",
  },

  // GuidedPractiseExerciseList

  seeAllExercisesBtn: {
    en: "See all exercises",
    fr: "VOIR TOUS MES EXERCICES",
  },
  guidedExerciseReviewValuesTxt: {
    en: "REVIEW MY VALUES",
    fr: "RÉVISER MES VALEURS",
  },
  guidedExerciseEndBtn: {
    en: "END",
    fr: "FIN",
  },
  guidedExerciseDoAgainBtn: {
    en: "I WANT TO DO THIS AGAIN",
    fr: "JE VEUX RECOMMENCER",
  },

  // UnstickMyThoughts

  unstickMyThoughtsHeaderTitle: {
    en: "Unstick My Thoughts",
    fr: "Décoller mes pensées",
  },

  // GetHelp

  getHelpTitle: {
    en: "Get Help",
    fr: "Obtenir de l’aide",
  },
  getHelpYourNotAlone: {
    en: "You're not alone",
    fr: "Vous n’êtes pas seul",
  },
  iAmInCrisisTab: {
    en: "I’m in Crisis",
    fr: "Je suis en crise",
  },
  myContactsTab: {
    en: "My Contacts",
    fr: "Mes contacts",
  },
  helplinesTab: {
    en: "Helplines",
    fr: "Lignes de secours",
  },
  crisisHeadLine: {
    en: "If you need immediate assistance, there's support for you:",
    fr: "Si vous avez besoin d’une aide immédiate, il existe des ressources pour vous : ",
  },
  call: {
    en: "Call",
    fr: "Appelez le",
  },
  nearestHospital: {
    en: "Go to your nearest hospital",
    fr: "Rendez-vous à l’hôpital le plus proche",
  },
  textTo: {
    en: "Text ",
    fr: "Envoyez ",
  },
  home: {
    en: "HOME",
    fr: "PARLER",
  },
  to: {
    en: "to ",
    fr: "au ",
  },
  available24: {
    en: "(available 24/7)",
    fr: "(disponible 24/7)",
  },
  callCanadaSuicide: {
    en: "Call Canada Suicide Prevention Service:",
    fr: "Appelez le service de prévention du suicide des Services de crises du Canada : ",
  },
  fromQuebec: {
    en: "Or from Quebec, call",
    fr: "OU au Québec : ",
  },
  contactTrustedFriend: {
    en: "Contact a trusted friend or family member immediately",
    fr: "Communiquer immédiatement avec un ami ou un membre de ma famille en qui j’ai confiance",
  },
  mentalHealth: {
    en: "Mental Health:",
    fr: "Santé mentale :",
  },
  kidsHelpline: {
    en: "Kids Helpline:",
    fr: "Jeunesse, J’écoute :",
  },
  support24: {
    en: "(24/7 support)",
    fr: "(disponible 24/7)",
  },
  wellnessHelpline: {
    en: "Hope for Wellness Helpline for Indigenous Peoples:",
    fr: "Ligne d’écoute d’espoir pour le mieux-être pour les peuples autochtones :",
  },
  addiction: {
    en: "Addiction:",
    fr: "Dépendance :",
  },

  // SideMenu

  sideMenuHiTitle: {
    en: "Hi",
    fr: "Bonjour",
  },
  sideMenuMyPlanTitle: {
    en: "My Plan",
    fr: "Mon Plan",
  },
  sideMenuRemindersTitle: {
    en: "Reminders",
    fr: "Rappels",
  },
  sideMenuDailyReviewTitle: {
    en: "Daily Check-In",
    fr: "Contrôle quotidien",
  },
  sideMenuDailyReviewNewTitle: {
    en: "Daily Check-In New",
    fr: "Contrôle quotidien nouveau",
  },
  sideMenuProgressTitle: {
    en: "Progress",
    fr: "Progression",
  },
  sideMenuCommitmentsTitle: {
    en: "Values",
    fr: "Valeurs",
  },
  sideMenuGratitudeListTitle: {
    en: "Gratitude List",
    fr: "Liste Des Raisons D’être Reconnaissant",
  },
  sideMenuDailyMessageTitle: {
    en: "Daily Message",
    fr: "Message Quotidien",
  },
  sideMenuSosTitle: {
    en: "SOS",
    fr: "SOS",
  },
  sideMenuJournalTitle: {
    en: "Journal",
    fr: "Journal",
  },
  sideMenuResourcesTitle: {
    en: "Resources",
    fr: "Ressources",
  },
  sideMenuSettingsTitle: {
    en: "Settings",
    fr: "Paramètres",
  },
  sideMenuLogoutTitle: {
    en: "Logout",
    fr: "Déconnexion",
  },
  sideMenuToolsTitle: {
    en: "Tools",
    fr: "Outils",
  },

  // tutorial
  onboarding_title1: {
    en: "Welcome to Wagon!",
    fr: "Bienvenue à Wagon!",
  },
  onboarding_description1: {
    en: "Your recovery plan in the palm of your hand, synchronized with your team.",
    fr: "Votre plan de récupération dans la paume de votre main, synchronisé avec votre équipe.",
  },
  onboarding_title2: {
    en: "Check off your goals",
    fr: "Cochez vos Objectifs",
  },
  onboarding_description2: {
    en: "Check off your customized daily, weekly, and monthly goals as you complete them.",
    fr: "Cochez vos objectifs quotidiens, hebdomadaires et mensuels personnalisés au fur et à mesure que vous les remplissez.",
  },
  onboarding_title3: {
    en: "Progress",
    fr: "Le Progrès",
  },
  onboarding_description3: {
    en: "See detailed data and analytics about your recovery progress.",
    fr: "Voir des données détaillées et des analyses sur vos progrès de récupération.",
  },
  onboarding_title4: {
    en: "Journal",
    fr: "Journal",
  },
  onboarding_description4: {
    en: "Write personal entries into your private journal.",
    fr: "Écrire des entrées personnelles dans votre journal privé.",
  },
  onboarding_title5: {
    en: "Daily Review",
    fr: "Revue Quotidienne",
  },
  onboarding_description5: {
    en: "Keep track of your progress by completing your daily review.",
    fr: "Suivez vos progrès en complétant votre revue quotidienne.",
  },
  onboarding_title6: {
    en: "Reminders",
    fr: "Rappels",
  },
  onboarding_description6: {
    en: "Set reminders to alert you when it's time to complete your important goals.",
    fr: "Définir des rappels pour vous alerter quand il est temps de remplir vos objectifs importants.",
  },
  onboarding_title7: {
    en: "Gratitude List",
    fr: "Liste de gratitude",
  },
  onboarding_description7: {
    en: "See what you're grateful for in your personal gratitude list.",
    fr: "Voyez ce que vous êtes reconnaissant dans votre liste de gratitude personnelle.",
  },
  onboarding_title8: {
    en: "SOS",
    fr: "SOS",
  },
  onboarding_description8: {
    en: "Quick access to emergency numbers.",
    fr: "Accès rapide aux numéros d'urgence.",
  },
  onboarding_getstarted_btn: {
    en: "Lets get started",
    fr: "Commençons",
  },
  onboarding_skip_btn: {
    en: "Skip",
    fr: "Sauter",
  },
  onboarding_next_btn: {
    en: "Next",
    fr: "Prochain",
  },
  onboarding_back_btn: {
    en: "Back",
    fr: "Retour",
  },

  // SETTINGS

  wagonDailyMessageTitle: {
    en: "Wagon's Daily Message",
    fr: "Message quotidien Wagon",
  },
  dailyReviewReminderTitle: {
    en: "Daily Check-In Reminder",
    fr: "Contrôle quotidien",
  },
  reminderTimeTitle: {
    en: "Reminder Time:",
    fr: "Heure de rappel:",
  },
  todayCompletionRemindersTitle: {
    en: "Goal Completion Reminder",
    fr: "Rappel d'achèvement d'objectif",
  },
  detectSlipperyLocationTitle: {
    en: "Detect Slippery Location",
    fr: "Détecter les endroits glissants",
  },
  changePasswordTitle: {
    en: "Change Password",
    fr: "Changer le mot de passe",
  },

  // Change Password

  oldPassword: {
    en: "Old Password",
    fr: "Nouveau mot de passe",
  },
  backToSettingsBtn: {
    en: "Back to settings",
    fr: "Retour aux paramètres",
  },
  changePassSucsMesg: {
    en: "Your password has been successfully changed.",
    fr: "Votre mot de passe a été changé avec succès.",
  },
  changePassErrorMesg1: {
    en: "Some of the data you entered isn't correct.",
    fr: "Certaines des données que vous avez entrées ne sont pas correctes.",
  },
  changePassErrorMesg2: {
    en: "Please try again.",
    fr: "Veuillez réessayer.",
  },

  //notifications

  dailyReviewLocalNotificationReminder: {
    en: "It's time to complete your Daily Check-In!",
    fr: "Il est temps de faire votre contrôle quotidien!",
  },
  completedGoalsNotificationReminder: {
    en: "Don’t forget to check off your completed goals!",
    fr: "N'oubliez pas de cocher vos objectifs complétés!",
  },

  // new localizations

  singleGoalSubTextLastDay: {
    en: "Last day to complete goal",
    fr: "Dernier jour pour atteindre l’objectif",
  },
  singleGoalSubTextOverdue: {
    en: "day overdue",
    fr: "jour excédents la date fixée pour la réalisation de l’objectif",
  },
  singleGoalSubTextOverduePlural: {
    en: "days overdue",
    fr: "jours excédents la date fixée pour la réalisation de l’objectif",
  },
  singleGoalSubText: {
    en: "day remaining until due date",
    fr: "jour avant la réalisation de l’objectif",
  },
  singleGoalSubTextPlural: {
    en: "days remaining until due date",
    fr: "jours avant la réalisation de l’objectif",
  },

  // Ground myself

  groundMyselfTitle: {
    en: "Ground Myself",
    fr: "Me fondre",
  },
  groundMyselfAllTab: {
    en: "All",
    fr: "Tout",
  },
  groundMyselfAudioTab: {
    en: "Audio",
    fr: "L'audio",
  },
  groundMyselfVideoTab: {
    en: "Video",
    fr: "Vidéo",
  },

  //AudioPlayerScreen
  bufferingSubTitle: {
    en: "Buffering ...",
    fr: "Buffering ...",
  },
  nowPlayingSubTitle: {
    en: "Now playing",
    fr: "Now playing",
  },
  pausedSubTitle: {
    en: "Paused",
    fr: "Paused",
  },
  errorSubTitle: {
    en: "Error, please try later ...",
    fr: "Error, please try later ...",
  },

  // Client app
  sessionHeading: {
    en: "Sessions",
    fr: "Sessions",
  },
  sessionTitle: {
    en: "Session",
    fr: "Session",
  },
  materialsTitle: {
    en: "Materials",
    fr: "Matériel",
  },

  programsTitle: {
    en: "Group Selection",
    fr: "Choisir le Groupe",
  },
  programsTitleSwitch: {
    en: "Group Change",
    fr: "Changement de groupe",
  },
  required: {
    en: "Required",
    fr: "Obligatoire",
  },
  rightsReserved: {
    en: "All rights reserved",
    fr: "Tous les droits sont réservés",
  },
  usedLink: {
    en: "This link for reset password is already used.",
    fr: "Ce lien pour réinitialiser le mot de passe est déjà utilisé.",
  },
  passwordSuccesfullyChanged: {
    en: "Password successfully changed",
    fr: "Mot de passe changé avec succès",
  },
  counsellor: {
    en: "Counsellor/Therapist",
    fr: "Conseiller",
  },
  date: {
    en: "Date",
    fr: "Date",
  },
  time: {
    en: "Time",
    fr: "Temps",
  },
  duration: {
    en: "Duration",
    fr: "Durée",
  },
  joinSession: {
    en: "Join Session",
    fr: "Rejoindre la session",
  },
  download: {
    en: "Download",
    fr: "Télécharger",
  },
  portalLoginSuccess: {
    en: "Successfully logged into portal",
    fr: "Connexion réussie au portail",
  },
  invalidPassCombination: {
    en: "Invalid Username and Password Combination!",
    fr: "Combinaison de nom d'utilisateur et de mot de passe invalide!",
  },
  emailSentSuccess: {
    en: "Email successfully sent",
    fr: "Email envoyé avec succès",
  },
  invalidEmail: {
    en: "Invalid email",
    fr: "Email invalide",
  },
  sendResetPasswordLink: {
    en: "Send reset link",
    fr: "Envoyer un lien",
  },
  sendEmailBtn: {
    en: "Send email",
    fr: "Envoyer un email",
  },
  timezoneUpdatedSuccess: {
    en: "Time zone updated successfully",
    fr: "FR:Time zone updated successfully",
  },
  noOnlineSession: {
    en: "You are not currently registered for an EHN Online session. If you would like to register for any of our online treatment or counselling programs, please call us at 866-348-1529.",
    fr: "Vous n'êtes pas actuellement inscrit à une session EHN Online. Si vous souhaitez vous inscrire à l'un de nos programmes de traitement ou de conseil en ligne, veuillez nous appeler au 866-348-1529.",
  },
  noClientRequest: {
    en: "You currently don't have any Treatment request.",
    fr: "Vous n'avez actuellement aucune demande de traitement.",
  },
  currentTimeZone: {
    en: "Current time zone",
    fr: "Fuseau horaire actuel",
  },
  timeZoneNotSet: {
    en: "Your current time zone is not set. Please choose your time zone in order to see your sessions and group selection.",
    fr: "Votre fuseau horaire actuel n'est pas défini. Veuillez choisir votre fuseau horaire afin de voir vos sessions et votre sélection de groupe.",
  },
  timeZoneNotSetModal1: {
    en: "Your current time zone is not set.",
    fr: "Votre fuseau horaire actuel n'est pas défini.",
  },
  timeZoneNotSetModal2: {
    en: "Please choose your time zone in order to see your sessions and group selection.",
    fr: "Veuillez choisir votre fuseau horaire afin de voir vos sessions et votre sélection de groupe.",
  },
  selectTimeZone: {
    en: "Select time zone",
    fr: "Sélectionnez le fuseau horaire",
  },
  selectTimeZoneMessage: {
    en: "Please select your time zone to view your online sessions.",
    fr: "Veuillez sélectionner votre fuseau horaire pour visualiser vos sessions en ligne.",
  },
  timeZoneValidationMsg: {
    en: "Please select your time zone.",
    fr: "Veuillez sélectionner votre fuseau horaire.",
  },
  updateTimeZone: {
    en: "Update",
    fr: "Réactualiser",
  },
  backBtn: {
    en: "Back",
    fr: "Retour",
  },
  sessionCancelled: {
    en: "The session has been cancelled. There is no programming scheduled during this time.",
    fr: "La session a été annulée. Il n’y a pas de programmation prévue pendant cette période.",
  },
  programIntroTitle: {
    en: "Group Selection",
    fr: "Choisir le Groupe",
  },

  programIntroText1: {
    en: "It's time to choose your group(s) for the next phase of your programming! Spaces are limited! Please take a few minutes to choose your group time(s).",
    fr: "C'est le moment de choisir votre/vos groupe(s) pour la prochaine phase de votre programme! Les places sont limitées! Veuillez prendre quelques minutes pour choisir l'horaire de votre/vos groupe(s).",
  },
  programIntroText3: {
    en: "Would you like to continue?",
    fr: "Voulez-vous continuer?",
  },
  selectGroupTitle: {
    en: "SELECT ADDITIONAL GROUP",
    fr: "SELECT ADDITIONAL GROUP",
  },
  noGroupsAvailable: {
    en: "SCHEDULING ERROR",
    fr: "ERREUR DE PLANIFICATION",
  },
  programSelectionWarning: {
    en: "PROGRAMS",
    fr: "PROGRAMMES",
  },
  programWarningMessage1: {
    en: "This group has 2 or fewer spaces remaining, which may be filled before we receive your request. Please select an additional group time as a second choice in case there is no longer capacity in your preferred group.",
    fr: "Ce groupe a moins de deux places de disponibles, qui peuvent être prises avant que nous recevions votre demande. Veuillez sélectionner une autre heure de groupe comme deuxième choix au cas où votre premier choix de groupe n'est plus disponible.",
  },
  programWarningMessage3: {
    en: "This group has 2 or fewer spaces remaining, which may be filled before we receive your request. Should this occur, we will contact you to discuss other options.",
    fr: "Ce groupe a moins de deux places de disponibles, qui peuvent être prises avant que nous recevions votre demande. Si cela arrive, nous vous contacterons pour discuter des options possibles.",
  },
  programWarningMessage4: {
    en: "There has been a scheduling error, please contact your Client Care Coordinator for assistance with registration.",
    fr: "Erreur de planification. Veuillez contacter votre coordonateur/coordonatrice pour obtenir de l'aide concernant l'inscription.",
  },
  programSelectionSuccess: {
    en: "Your Selection has successfully been submitted for review.",
    fr: "Votre choix a été soumis pour révision.",
  },
  programSelectionError: {
    en: "There has been an error with submitting your request.  Please try again later.",
    fr: "Une erreur est survenue en soumettant votre choix. Veuillez réessayer plus tard.",
  },
  chooseOption: {
    en: "Choose Now",
    fr: "Choisir Maintenant",
  },
  laterOption: {
    en: "Later",
    fr: "Choisir plus tard",
  },
  confirm: {
    en: "Confirm",
    fr: "Confirmez",
  },
  close: {
    en: "Close",
    fr: "Fermer",
  },
  /* Start: ModalDeselectGroupInfo */
  modalDeselectGroupTitle: {
    en: "Deselect Group",
    fr: "Désélectionner Groupe",
  },

  modalDeselectGroupMessagePart1: {
    en: "You have already selected",
    fr: "You have already selected",
  },
  modalDeselectGroupMessagePart2: {
    en: "group on ",
    fr: "group on ",
  },
  modalDeselectGroupMessagePart4: {
    en: " starting from the week of ",
    fr: " starting from the week of ",
  },
  modalDeselectGroupMessagePart3: {
    en: "Please deselect that group before selecting another one.",
    fr: "Please deselect that group before selecting another one.",
  },
  /* End: ModalDeselectGroupInfo */

  introParagraphPart1: {
    en: "Your program finishes ",
    fr: "Votre programme se termine le ",
  },
  introParagraphPart2: {
    en: "Please choose a weekly group time for the next portion of your program, which will begin the week of",
    fr: "Veuillez choisir un horaire pour votre suivi hebdomadaire pour la prochaine portion de votre programme, qui débutera la semaine du",
  },
  introParagraphPart3: {
    en: "Space is limited so make your selection as soon as possible.",
    fr: "Les places sont limitées alors veuillez faire votre choix le plus rapidement possible.",
  },
  introParagraphPart4: {
    en: "Please keep in mind that you cannot select 2 groups on the same day.",
    fr: "Veuillez garder en tête que vous ne pouvez pas choisir 2 groupes dans la même journée.",
  },
  introParagraphPart5: {
    en: "Please review your group selection before submitting your request.",
    fr: "Veuillez réviser votre choix d'horaire de groupe avant de soumettre votre demande.",
  },
  introParagraphPart6: {
    en: "Your client care coordinator will review your selection to ensure space is still available and then send you an email confirmation.",
    fr: "Votre coordonateur/coordonatrice de service aux clients va réviser votre choix afin de s'assurer que le groupe est encore disponible et vous enverra un courriel de confirmation.",
  },
  introParagraphPart7: {
    en: "Please select a group time for the next group in your program.",
    fr: "Veuillez choisir un horaire pour le prochain groupe dans votre programme.",
  },
  switchParagraphPart1: {
    en: "You have requested to change the session time of ",
    fr: "Veuillez choisir un horaire pour le prochain groupe dans votre programme.",
  },
  reviewAndSubmit: {
    en: "Review and Submit",
    fr: "Réviser et Soumettre",
  },
  deselect: {
    en: "Deselect",
    fr: "Désélectionner",
  },
  select: {
    en: "Select",
    fr: "Sélectionner",
  },
  previous: {
    en: "Previous",
    fr: "Précédent",
  },
  next: {
    en: "Next",
    fr: "Suivant",
  },
  submit: {
    en: "Submit",
    fr: "Soumettre",
  },
  spaces: {
    en: "Spaces",
    fr: "Disponibilités",
  },
  groupName: {
    en: "Group Name",
    fr: "Group Name",
  },

  startDateTime: {
    en: "Start Date/Time",
    fr: "Date de commencement/Heure",
  },
  switchStartDateTime: {
    en: "Change Date/Time",
    fr: "Date de commencement/Heure",
  },
  endDateTime: {
    en: "End Date/Time",
    fr: "Date de fin/Heure",
  },
  rejected: {
    en: "Rejected",
    fr: "Rejeté",
  },
  approved: {
    en: "Approved",
    fr: "Approuvé",
  },
  pending: {
    en: "Pending",
    fr: "En Attente",
  },
  group: {
    en: "Group",
    fr: "Groupe",
  },
  submittedRequest: {
    en: "Submitted Request",
    fr: "Soumettre la demande",
  },
  treatmentsTitle: {
    en: "Treatment Programs",
    fr: "Traitements",
  },
  action: {
    en: "Action",
    fr: "Action",
  },
  status: {
    en: "Status",
    fr: "État",
  },
  selectGroup: {
    en: "Select Group",
    fr: "Sélectionnez Groupe",
  },
  noGroupsOnDate: {
    en: "There are currently no groups with spaces available during this time period.",
    fr: "Il n'y a pas de groupe ou de disponibilité durant la période choisit.",
  },
  selectADay: {
    en: "Select a day:",
    fr: "Veuillez choisir une journée:",
  },
  selectFirstAvailableWeek: {
    en: "Select week to start",
    fr: "Sélectionner la semaine où vous allez débuter",
  },
};
