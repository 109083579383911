import React from "react";
import localizationService from "../../services/localization-service";
import { useSelector } from "react-redux";

const ModalDeselectGroupInfo = (props) => {
  const selectedlang = useSelector((state) => state.account.lang);
  const group = props.group;
  const day = props.day;
  const selectedStartWeek = props.selectedStartWeek;
  const requestType = props.requestType;

  return (
    <div
      className="modal fade show"
      id="wagon-confirm-dialog"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={props.openModal ? { display: "block" } : { display: "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header programs">
            <h6 className="modal-title text-white" id="exampleModalLabel">
              {localizationService.getLocalizationValue(
                "modalDeselectGroupTitle",
                selectedlang
              )}
            </h6>
          </div>
          <div className="modal-body">
            <div className="container mt-3">
              <div className="row">
                <div className="col">
                  <div>
                    <p>
                      {localizationService.getLocalizationValue(
                        "modalDeselectGroupMessagePart1",
                        selectedlang
                      )}{" "}
                      <b>"{group}"</b>{" "}
                      {localizationService.getLocalizationValue(
                        "modalDeselectGroupMessagePart2",
                        selectedlang
                      )}
                      {day}
                      {requestType == 1 ? (
                        <>
                          {localizationService.getLocalizationValue(
                            "modalDeselectGroupMessagePart4",
                            selectedlang
                          )}
                          {selectedStartWeek}
                        </>
                      ) : (
                        <></>
                      )}
                      .
                    </p>
                    <p>
                      {localizationService.getLocalizationValue(
                        "modalDeselectGroupMessagePart3",
                        selectedlang
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          &nbsp;
          <div className="modal-footer">
            <div className="mx-auto">
              <button
                className="btn btn-secondary program-color"
                onClick={props.closeModal}
              >
                {" "}
                {localizationService.getLocalizationValue(
                  "close",
                  selectedlang
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeselectGroupInfo;
