import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../store";
import logo from "../../assets/img/EHN-Online-Logo.png";
import {
  login,
  forgotPassword,
  changeUserLang,
} from "../../actions/account.action";
import notification from "../../services/notification-service";
import localizationService from "../../services/localization-service";
import { Oval } from "react-loader-spinner";
import { openRequestModal } from "../../actions/portal.action";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const selectedlang = useSelector((state) => state.account.lang);
  const [isSetOrResetAccount, setResetOrAcc] = useState({
    resetPass: false,
    setAccount: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const loginSubmiting = useSelector((state) => state.account.loginSubmiting);
  const forgotPasswordSubmiting = useSelector(
    (state) => state.account.forgotPasswordSubmiting
  );
  const onSubmit = (values) => {
    dispatch(login(values.email, values.password))
      .then((result) => {
        notification.success(
          localizationService.getLocalizationValue(
            "portalLoginSuccess",
            result.Lang
          )
        );

        if (result.HasRequest === true) {
          dispatch(openRequestModal());
        }

        history.push("/");
      })
      .catch((error) => {
        notification.error(
          localizationService.getLocalizationValue(
            "invalidPassCombination",
            selectedlang
          )
        );
      });
  };

  let body = window.document.body;
  body.classList.add("login-page");
  const onForgot = (values) => {
    dispatch(forgotPassword(values.email))
      .then(() => {
        notification.success(
          localizationService.getLocalizationValue(
            "emailSentSuccess",
            selectedlang
          )
        );
        setResetOrAcc({
          resetPass: false,
          setAccount: false,
        });
      })
      .catch((error) => {
        notification.error(
          localizationService.getLocalizationValue("invalidEmail", selectedlang)
        );
      });
  };

  const changeLang = (lang) => {
    dispatch(changeUserLang(lang));
  };
  const cancel = () => {
    setResetOrAcc({
      resetPass: false,
      setAccount: false,
    });
  };
  const resetPassword = () => {
    setResetOrAcc({
      resetPass: true,
      setAccount: false,
    });
  };
  const setAccount = () => {
    setResetOrAcc({
      resetPass: false,
      setAccount: true,
    });
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <section className="text-center">
      <div
        className="overlay"
        style={loginSubmiting !== true ? { display: "none" } : {}}
      >
        <Oval
          display="block"
          marginLeft="auto"
          marginRight="auto"
          color="#00BFFF"
          secondaryColor="#99e6ff"
          height={100}
          width={100}
          visible={loginSubmiting !== true ? false : true}
          ariaLabel="loading-indicator"
        />
      </div>
      <div
        className="overlay"
        style={forgotPasswordSubmiting !== true ? { display: "none" } : {}}
      >
        <Oval
          display="block"
          marginLeft="auto"
          marginRight="auto"
          color="#00BFFF"
          secondaryColor="#99e6ff"
          height={100}
          width={100}
          visible={forgotPasswordSubmiting !== true ? false : true}
          ariaLabel="loading-indicator"
        />
      </div>
      <div className="form-signin text-left">
        <div className="language d-flex justify-content-end">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              onClick={() => changeLang("en-CA")}
              className={
                "btn btn-light " + (selectedlang === "en-CA" && "active")
              }
            >
              EN
            </button>
            <button
              type="button"
              onClick={() => changeLang("fr-CA")}
              className={
                "btn btn-light " + (selectedlang === "fr-CA" && "active")
              }
            >
              FR
            </button>
          </div>
        </div>
        {isSetOrResetAccount.resetPass === false &&
        isSetOrResetAccount.setAccount === false ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={logo} alt="" className="img-fluid" />
            <label htmlFor="email" className="sr-only">
              {localizationService.getLocalizationValue(
                "successResetEmailSecond",
                selectedlang
              )}
            </label>
            <input
              name="email"
              className="form-control"
              placeholder={localizationService.getLocalizationValue(
                "successResetEmailSecond",
                selectedlang
              )}
              {...register("email", {
                required: localizationService.getLocalizationValue(
                  "required",
                  selectedlang
                ),
              })}
              autoFocus
            />
            {errors.email ? (
              <div className="alert alert-danger" role="alert">
                {errors.email.message}
              </div>
            ) : null}

            <label htmlFor="inputPassword" className="sr-only">
              {localizationService.getLocalizationValue(
                "password",
                selectedlang
              )}
            </label>
            <div className="input-group password">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="form-control"
                placeholder={localizationService.getLocalizationValue(
                  "password",
                  selectedlang
                )}
                autoFocus
                {...register("password", {
                  required: localizationService.getLocalizationValue(
                    "required",
                    selectedlang
                  ),
                  validate: (value) => value !== "dsds" || "dsds ",
                })}
              />
              {errors.password ? (
                <div className="alert alert-danger" role="alert">
                  {errors.password.message}
                </div>
              ) : null}
              <button
                className="btn ico-show-hide"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i
                  className={"fa " + (showPassword ? "fa-eye" : "fa-eye-slash")}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            <button
              type="submit"
              className="w-100 btn btn-lg mt-1 mb-2 btn-success btn-green-light text-uppercase ico-l"
            >
              <i className="fal fa-sign-in"></i>
              {localizationService.getLocalizationValue("login", selectedlang)}
            </button>
            <a
              className="forgot-password d-block mt-4 fs-09rem"
              onClick={() => resetPassword()}
            >
              {localizationService.getLocalizationValue(
                "forgotPasswordBtn",
                selectedlang
              )}
              ?
            </a>
            <a
              className="forgot-password d-block mt-4 fs-09rem"
              onClick={() => setAccount()}
            >
              {localizationService.getLocalizationValue(
                "createAccBtn",
                selectedlang
              )}
            </a>
          </form>
        ) : (
          <form onSubmit={handleSubmit(onForgot)}>
            <img src={logo} alt="" className="img-fluid" />
            <h1 className="h3 mb-4 mt-2 fw-normal">
              {isSetOrResetAccount.resetPass
                ? localizationService.getLocalizationValue(
                    "resetPasswordTitle",
                    selectedlang
                  )
                : localizationService.getLocalizationValue(
                    "createAccBtn",
                    selectedlang
                  )}
            </h1>
            <label htmlFor="email" className="sr-only">
              {localizationService.getLocalizationValue(
                "successResetEmailSecond",
                selectedlang
              )}
            </label>
            <input
              name="email"
              className="form-control rounded mt-3 mb-4"
              placeholder={localizationService.getLocalizationValue(
                "successResetEmailSecond",
                selectedlang
              )}
              {...register("email", {
                required: localizationService.getLocalizationValue(
                  "required",
                  selectedlang
                ),
              })}
              autoFocus
            />
            {errors.email ? (
              <div className="alert alert-danger" role="alert">
                {errors.email.message}
              </div>
            ) : null}
            <div className="row mt-1 mb-2">
              <div className="col">
                <button
                  className="w-100 btn btn-success btn-green-light text-uppercase text-nowrap"
                  type="submit"
                >
                  {localizationService.getLocalizationValue(
                    "sendEmailBtn",
                    selectedlang
                  )}
                </button>
              </div>
              <div className="col-auto">
                <button
                  className="w-100 btn btn-light text-uppercase"
                  onClick={() => cancel()}
                >
                  {localizationService.getLocalizationValue(
                    "createCopeItemCancelBtn",
                    selectedlang
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
        <p className="mt-4 text-muted fs-11px">
          &copy; {getCurrentYear()} onthewagon.ca.{" "}
          {localizationService.getLocalizationValue(
            "rightsReserved",
            selectedlang
          )}
          .
        </p>
      </div>
    </section>
  );
};
