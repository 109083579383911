import config from "../config";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESSFUL = "FORGOT_PASSWORD_SUCCESSFUL";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESSFUL = "RESET_PASSWORD_SUCCESSFUL";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const USER_CAN_RESET_PASSWORD = "USER_CAN_RESET_PASSWORD";
export const USER_CAN_RESET_PASSWORD_SUCCESSFUL =
  "USER_CAN_RESET_PASSWORD_SUCCESSFUL";
export const USER_CAN_RESET_PASSWORD_FAIL = "USER_CAN_RESET_PASSWORD_FAIL";
export const LOGOUT = "LOGOUT";
export const LANGUAGE_CHANGED = "LANGUAGE_CHANGED";
export const GET_TIMEZONE_LOADING = "GET_TIMEZONE_LOADING";
export const GET_TIMEZONE_LOADING_SUCCESSFUL =
  "GET_TIMEZONE_LOADING_SUCCESSFUL";
export const GET_TIMEZONE_LOADING_FAIL = "GET_TIMEZONE_LOADING_FAIL";
export const POST_TIMEZONE_LOADING = "POST_TIMEZONE_LOADING";
export const POST_TIMEZONE_LOADING_SUCCESSFUL =
  "POST_TIMEZONE_LOADING_SUCCESSFUL";
export const POST_TIMEZONE_LOADING_FAIL = "POST_TIMEZONE_LOADING_FAIL";

const portalInitialState = {
  loggedUser: null,
  loginSubmiting: false,
  forgotPasswordSubmiting: false,
  lang: sessionStorage.getItem(config.LANG),
  timeZone: ["Eastern Standard Time"],
  timeZoneLoading: false,
  timeZones: [
    { id: 0, name: "PST-Vancouver" },
    { id: 1, name: "MST-Edmonton" },
    { id: 6, name: "CST-Regina (No Daylight Savings)" },
    { id: 2, name: "CST-Winnipeg" },
    { id: 3, name: "EST-Toronto" },
    { id: 4, name: "AST-Halifax" },
    { id: 5, name: "NST-St. John's" },
  ],
  currentTimeZone: null,
};

const portalReducer = (state = portalInitialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loginSubmiting: true,
      };
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        loginSubmiting: false,
        loggedUser: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loginSubmiting: false,
        loggedUser: null,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordSubmiting: true,
      };
    case FORGOT_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        forgotPasswordSubmiting: false,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPasswordSubmiting: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        forgotPasswordSubmiting: true,
      };
    case RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        forgotPasswordSubmiting: false,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        forgotPasswordSubmiting: false,
      };
    case USER_CAN_RESET_PASSWORD:
      return {
        ...state,
        forgotPasswordSubmiting: true,
      };
    case USER_CAN_RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        forgotPasswordSubmiting: false,
      };
    case USER_CAN_RESET_PASSWORD_FAIL:
      return {
        ...state,
        forgotPasswordSubmiting: false,
      };
    case LOGOUT:
      return {
        ...state,
        loggedUser: null,
        loginSubmiting: false,
      };
    case LANGUAGE_CHANGED:
      return {
        ...state,
        lang: action.payload,
      };

    case POST_TIMEZONE_LOADING:
      return {
        ...state,
        timeZoneLoading: true,
      };
    case POST_TIMEZONE_LOADING_SUCCESSFUL:
      return {
        ...state,
        sessions: action.payload,
        timeZoneLoading: false,
      };
    case POST_TIMEZONE_LOADING_FAIL:
      return {
        ...state,
        timeZoneLoading: false,
      };
    default: {
      return state;
    }
  }
};

export default portalReducer;
