export const GET_SESSIONS_LOADING = "GET_SESSIONS_LOADING";
export const GET_SESSIONS_LOADING_SUCCESSFUL = "GET_SESSIONS_LOADING_SUCCESSFUL";
export const GET_SESSIONS_LOADING_FAIL = "GET_SESSIONS_LOADING_FAIL";

export const GET_MATERIALS_LOADING = "GET_MATERIALS_LOADING";
export const GET_MATERIALS_LOADING_SUCCESSFUL = "GET_MATERIALS_LOADING_SUCCESSFUL";
export const GET_MATERIALS_LOADING_FAIL = "GET_MATERIALS_LOADING_FAIL";

export const GET_REQUEST_LOADING = "GET_REQUEST_LOADING";
export const GET_REQUEST_LOADING_SUCCESSFUL = "GET_REQUEST_LOADING_SUCCESSFUL";
export const GET_REQUEST_LOADING_FAIL = "GET_REQUEST_LOADING_FAIL";

export const GET_SUBMITTED_LOADING = "GET_REQUEST_LOADING";
export const GET_SUBMITTED_LOADING_SUCCESSFUL = "GET_REQUEST_LOADING_SUCCESSFUL";
export const GET_SUBMITTED_LOADING_FAIL = "GET_REQUEST_LOADING_FAIL";

export const SEND_REQUEST_LOADING = "SEND_REQUEST_LOADING";
export const SEND_REQUEST_LOADING_SUCCESSFUL = "SEND_REQUEST_LOADING_SUCCESSFUL";
export const SEND_REQUEST_LOADING_FAIL = "SEND_REQUEST_LOADING_FAIL";

export const REQUEST_MODAL_OPEN = "REQUEST_MODAL_OPEN";
export const REQUEST_MODAL_CLOSE = "REQUEST_MODAL_CLOSE";

const portalInitialState = {
  sessions: [],
  sessionsLoading: false,
  materials: [],
  materialsLoading: false,
  request: [],
  requestLoading: false,
  submitted: [],
  submittedLoading: false,
  sendRequest: [],
  sendRequestLoading: false,
  loading: true,
  openRequestModal: false,
};

const portalReducer = (state = portalInitialState, action) => {
  switch (action.type) {
    case GET_SESSIONS_LOADING:
      return {
        ...state,
        //sessions:[],
        sessionsLoading: true,
      };
    case GET_SESSIONS_LOADING_SUCCESSFUL:
      return {
        ...state,
        sessions: action.payload,
        sessionsLoading: false,
      };
    case GET_SESSIONS_LOADING_FAIL:
      return {
        ...state,
        sessionsLoading: false,
      };

    case GET_MATERIALS_LOADING:
      return {
        ...state,
        materials: [],
        materialsLoading: true,
      };
    case GET_MATERIALS_LOADING_SUCCESSFUL:
      return {
        ...state,
        materials: action.payload,
        materialsLoading: false,
      };
    case GET_MATERIALS_LOADING_FAIL:
      return {
        ...state,
        materials: [],
        materialsLoading: false,
      };
    case GET_REQUEST_LOADING:
      return {
        ...state,
        request: [],
        requestLoading: true,
      };
    case GET_REQUEST_LOADING_SUCCESSFUL:
      return {
        ...state,
        request: action.payload,
        requestLoading: false,
      };
    case GET_REQUEST_LOADING_FAIL:
      return {
        ...state,
        request: [],
        requestLoading: false,
      };

    case GET_SUBMITTED_LOADING:
      return {
        ...state,
        submitted: [],
        submittedLoading: true,
      };
    case GET_SUBMITTED_LOADING_SUCCESSFUL:
      return {
        ...state,
        submitted: action.payload,
        submittedLoading: false,
      };
    case GET_SUBMITTED_LOADING_FAIL:
      return {
        ...state,
        submitted: [],
        submittedLoading: false,
      };

    case SEND_REQUEST_LOADING:
      return {
        ...state,
        sendRequestLoading: true,
      };
    case SEND_REQUEST_LOADING_SUCCESSFUL:
      return {
        ...state,
        sendRequest: action.payload,
        sendRequestLoading: false,
      };
    case SEND_REQUEST_LOADING_FAIL:
      return {
        ...state,
        sendRequestLoading: false,
      };

    case REQUEST_MODAL_OPEN:
      return {
        ...state,
        openRequestModal: true,
      };
    case REQUEST_MODAL_CLOSE:
      return {
        ...state,
        openRequestModal: false,
      };

    default: {
      return state;
    }
  }
};

export default portalReducer;
