import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "../store";
import PrivateRoute from "../framework/private-route";

/** Import pages */
import { DashboardPage } from "../pages/dashboard-page";
import { LoginPage } from "../pages/account/login-page";
import { ResetPasswordPage } from "../pages/account/reset-password-page";
import { MaterialsPage } from "../pages/materials/materials-page";
import { ProgramsPage } from "../pages/programs/programs-page";

export const RootRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        {/* PUBLIC ROUTES */}
        <Route exact path="/login" component={LoginPage} />
        <Route
          exact
          path="/password-update/:email/:guid/:lang"
          component={ResetPasswordPage}
        />

        {/* PRIVATE ROUTES */}
        <PrivateRoute exact path="/" component={DashboardPage} />
        <PrivateRoute exact path="/materials" component={MaterialsPage} />
        <PrivateRoute exact path="/programs" component={ProgramsPage} />
      </Switch>
    </Router>
  );
};
