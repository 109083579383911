import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../store";

import { useParams } from "react-router-dom";

import logo from "../../assets/img/EHN-Online-Logo.png";

import {
  canUserResetPassword,
  resetPassword,
  changeUserLang,
} from "../../actions/account.action";
import notification from "../../services/notification-service";
import localizationService from "../../services/localization-service";
import isLength from "validator/lib/isLength";
import matches from "validator/lib/matches";
import { Oval } from "react-loader-spinner";

export const ResetPasswordPage = () => {
  const dispatch = useDispatch();

  const selectedlang = useSelector((state) => state.account.lang);

  let { email, guid, lang } = useParams();

  const [userCanResetPassword, setUserCanResetPassword] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);

  const [passLengthError, setPassLengthError] = useState(false);
  const [upperLowerCaseError, setUpperLowerCaseError] = useState(false);
  const [numberSymbolError, setNumberSymbolError] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);

  const [passwordValue, setpasswordValue] = useState("");
  const [confirmPasswordValue, setconfirmPasswordValue] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const forgotPasswordSubmiting = useSelector(
    (state) => state.account.forgotPasswordSubmiting
  );

  useEffect(() => {
    changeLang(lang);

    onCheckPaswordReset();
  }, []);

  const onCheckPaswordReset = () => {
    dispatch(canUserResetPassword(email, guid))
      .then(() => {
        setUserCanResetPassword(true);
      })
      .catch((error) => {
        notification.error(error);
        setUserCanResetPassword(false);
      });
  };

  const updateInputValue = (value) => {
    setpasswordValue(value);

    if (isSubmitted) {
      if (!isLength(value, { min: 8, max: 16 })) {
        setPassLengthError(true);
      } else {
        setPassLengthError(false);
      }
      if (!matches(value, /(?=.*[A-Z])/) || !matches(value, /(?=.*[a-z])/)) {
        setUpperLowerCaseError(true);
      } else {
        setUpperLowerCaseError(false);
      }
      if (!matches(value, /[0-9]/) || !matches(value, /(?=.*[!@#$%^&])/)) {
        setNumberSymbolError(true);
      } else {
        setNumberSymbolError(false);
      }
    }
  };

  const checkIfPasswordsMatch = (passValue, confirmPassValue) => {
    setconfirmPasswordValue(confirmPassValue);
    if (isSubmitted) {
      if (passValue !== confirmPassValue) {
        setPasswordsDontMatch(true);
      } else {
        setPasswordsDontMatch(false);
      }
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = (values) => {
    setIsSubmitted(true);

    let isError = false;
    if (!isLength(values.password, { min: 8, max: 16 })) {
      setPassLengthError(true);
      isError = true;
    } else {
      setPassLengthError(false);
    }
    if (
      !matches(values.password, /(?=.*[A-Z])/) ||
      !matches(values.password, /(?=.*[a-z])/)
    ) {
      setUpperLowerCaseError(true);
      isError = true;
    } else {
      setUpperLowerCaseError(false);
    }
    if (
      !matches(values.password, /[0-9]/) ||
      !matches(values.password, /(?=.*[!@#$%^&])/)
    ) {
      setNumberSymbolError(true);
      isError = true;
    } else {
      setNumberSymbolError(false);
    }
    if (values.password !== values.confirmPassword) {
      setPasswordsDontMatch(true);
      isError = true;
    } else {
      setPasswordsDontMatch(false);
    }

    if (isError) {
      return;
    }

    dispatch(resetPassword(email, values.password, guid))
      .then(() => {
        notification.success(
          localizationService.getLocalizationValue(
            "passwordSuccesfullyChanged",
            selectedlang
          )
        );
        history.push("/login");
      })
      .catch((error) => {
        notification.error(error);
      });
  };

  const changeLang = (lang) => {
    dispatch(changeUserLang(lang));
  };

  return (
    <section className="text-center">
      <div
        className="overlay"
        style={forgotPasswordSubmiting !== true ? { display: "none" } : {}}
      >
        <Oval
          display="block"
          marginLeft="auto"
          marginRight="auto"
          color="#00BFFF"
          secondaryColor="#99e6ff"
          height={100}
          width={100}
          visible={forgotPasswordSubmiting !== true ? false : true}
          ariaLabel="loading-indicator"
        />
      </div>
      <div className="form-signin text-left">
        <div className="language d-flex justify-content-end">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              onClick={() => changeLang("en-CA")}
              className={
                "btn btn-light " + (selectedlang === "en-CA" && "active")
              }
            >
              EN
            </button>
            <button
              type="button"
              onClick={() => changeLang("fr-CA")}
              className={
                "btn btn-light " + (selectedlang === "fr-CA" && "active")
              }
            >
              FR
            </button>
          </div>
        </div>
        {userCanResetPassword ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={logo} alt="" className="img-fluid" />
            <h1 className="h3 mb-3 fw-normal">
              {localizationService.getLocalizationValue(
                "setPasswordTitle",
                selectedlang
              )}
            </h1>
            <label htmlFor="password" className="sr-only">
              {localizationService.getLocalizationValue(
                "password",
                selectedlang
              )}
            </label>

            <div>
              <p>
                {localizationService.getLocalizationValue(
                  "validationMainText",
                  selectedlang
                )}
              </p>
              <div className="p-1 inform-text">
                <span
                  className="inform-text-dot"
                  style={{
                    backgroundColor: passLengthError ? "red" : "#b2c235",
                  }}
                ></span>
                <span
                  style={{
                    paddingLeft: "10px",
                    color: passLengthError ? "red" : "#58585b",
                  }}
                >
                  {" "}
                  {localizationService.getLocalizationValue(
                    "validationLength",
                    selectedlang
                  )}
                </span>
              </div>
              <div className="p-1 inform-text">
                <span
                  className="inform-text-dot"
                  style={{
                    backgroundColor: upperLowerCaseError ? "red" : "#b2c235",
                  }}
                ></span>
                <span
                  style={{
                    paddingLeft: "10px",
                    color: upperLowerCaseError ? "red" : "#58585b",
                  }}
                >
                  {" "}
                  {localizationService.getLocalizationValue(
                    "validationUpperAndLowerCase",
                    selectedlang
                  )}
                </span>
              </div>
              <div className="p-1 inform-text">
                <span
                  className="inform-text-dot"
                  style={{
                    backgroundColor: numberSymbolError ? "red" : "#b2c235",
                  }}
                ></span>
                <span
                  style={{
                    paddingLeft: "10px",
                    color: numberSymbolError ? "red" : "#58585b",
                  }}
                >
                  {" "}
                  {localizationService.getLocalizationValue(
                    "validationNumberAndSymbol",
                    selectedlang
                  )}
                </span>
              </div>
              <div className="p-1 inform-text">
                <span
                  style={{
                    display: passwordsDontMatch ? "block" : "none",
                    color: "red",
                  }}
                >
                  {" "}
                  {localizationService.getLocalizationValue(
                    "passAndConfirmPassDontMatch",
                    selectedlang
                  )}
                </span>
              </div>
            </div>

            <div className="input-group password">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder={localizationService.getLocalizationValue(
                  "newPassword",
                  selectedlang
                )}
                {...register("password", {
                  // required: localizationService.getLocalizationValue('required', selectedlang),
                  // validate: value => value !== "dsds" || "dsds "
                })}
                value={passwordValue}
                onChange={(e) => {
                  updateInputValue(e.target.value);
                  checkIfPasswordsMatch(e.target.value, confirmPasswordValue);
                }}
              />
              {/* {
                                errors.password
                                    ? <div className="alert alert-danger" role="alert">{errors.password.message}</div>
                                    : null
                            } */}
              <button
                className="btn ico-show-hide"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i
                  className={"fa " + (showPassword ? "fa-eye" : "fa-eye-slash")}
                  aria-hidden="true"
                ></i>
              </button>
            </div>

            <label htmlFor="confirmPassword" className="sr-only">
              {localizationService.getLocalizationValue(
                "confirmPassword",
                selectedlang
              )}
            </label>
            <div className="input-group password">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="form-control"
                placeholder={localizationService.getLocalizationValue(
                  "confirmPassword",
                  selectedlang
                )}
                {...register("confirmPassword", {
                  // required: localizationService.getLocalizationValue('required', selectedlang),
                  // validate: value => value !== "dsds" || "dsds "
                })}
                value={confirmPasswordValue}
                onChange={(e) =>
                  checkIfPasswordsMatch(passwordValue, e.target.value)
                }
              />
              {/* {
                                errors.confirmPassword
                                    ? <div className="alert alert-danger" role="alert">{errors.confirmPassword.message}</div>
                                    : null
                            } */}
              <button
                className="btn ico-show-hide"
                type="button"
                onClick={() => setConfirmPassword(!showConfirmPassword)}
              >
                <i
                  className={
                    "fa " + (showConfirmPassword ? "fa-eye" : "fa-eye-slash")
                  }
                  aria-hidden="true"
                ></i>
              </button>
            </div>

            <button
              type="submit"
              className="w-100 btn btn-lg mt-1 mb-2 btn-success btn-green-light text-uppercase"
            >
              {localizationService.getLocalizationValue(
                "resetPasswordSubmitBtn",
                selectedlang
              )}
            </button>
            {/* <span className="sign-up d-block my-1 fs-09rem">Don't have an account? <a href="#">Sign up</a></span> */}
          </form>
        ) : (
          <form>
            <img src={logo} alt="" className="img-fluid" />
            <h1 className="h3 mb-4 mt-2 fw-normal">
              {localizationService.getLocalizationValue(
                "usedLink",
                selectedlang
              )}
            </h1>
          </form>
        )}
        <p className="mt-4 text-muted fs-11px">
          &copy; 2021 onthewagon.ca.{" "}
          {localizationService.getLocalizationValue(
            "rightsReserved",
            selectedlang
          )}
          .
        </p>
      </div>
    </section>
  );
};
