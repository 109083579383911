import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { logoutUser } from "../actions/account.action";
import { history } from "../store";
import { useSelector, useDispatch } from "react-redux";

import localizationService from "../services/localization-service";

import navLogo from "../assets/img/EHN-Online-Site.png";
import ActivityDetector from "activity-detector";
import config from "../config";
import storageService from "../services/storage-service";
import { postTimeZone } from "../actions/account.action";
import notification from "../services/notification-service";
import { closeRequestModal } from "../actions/portal.action";

import $ from "jquery";

const MasterPage = (props) => {
  const [currentTimeZone, setCurrentTimeZone] = useState(null);
  const [timeZoneCheck, setTimeZoneCheck] = useState(false);
  const dispatch = useDispatch();
  const selectedlang = useSelector((state) => state.account.lang);
  const location = useLocation();
  const [hasRequest, setHasRequest] = useState(false);
  const timeZones = useSelector((state) => state.account.timeZones);
  const [timeZoneModal, setTimeZoneModal] = useState(false);
  const [timeZoneSelectedId, setTimeZoneSelectedId] = useState(null);
  const openRequestModal = useSelector(
    (state) => state.portal.openRequestModal
  );
  const [userName, setUserName] = useState("");
  const [showTimeZoneValidationMsg, setshowTimeZoneValidationMsg] =
    useState(false);

  useEffect(() => {
    var ctz = storageService.getStorageItem(config.TIMEZONE);
    if (ctz != null) {
      setCurrentTimeZone(ctz);
      setTimeZoneCheck(false);

      if (openRequestModal) {
        setHasRequest(true);
      }
    } else {
      setTimeZoneCheck(true);
    }
  }, [currentTimeZone]);

  useEffect(() => {
    setUserName(storageService.getStorageItem(config.USERNAME));
  }, []);

  const [currentRoute, setCurrentRoute] = useState("/");

  const sessionTimeoutRef = useRef(null);

  const [isIdle, setIsIdle] = useState(false);

  const useIdle = (options) => {
    useEffect(() => {
      const activityDetector = new ActivityDetector(options);
      activityDetector.on("idle", () => setLogoutTimer());
      activityDetector.on("active", () => stayActive());

      return () => activityDetector.stop();
    }, [options]);
    return isIdle;
  };

  const stayActive = () => {
    clearTimeout(sessionTimeoutRef.current);
    setIsIdle(false);
  };
  const setLogoutTimer = () => {
    sessionTimeoutRef.current = setTimeout(onLogout, 1800000); //30 minute idle timeout
    //sessionTimeoutRef.current = setTimeout(onLogout, 5000); //5 second idle timeout (for testing)
  };

  const onLogout = () => {
    dispatch(logoutUser());
    sessionStorage.clear();
    clearTimeout(sessionTimeoutRef.current);
    history.push("/login");
  };

  //const autoLoggout = useIdle({ timeToIdle: 1 }); //initialize the idle timer

  useEffect(() => {
    let body = window.document.body;

    $('[data-toggle="offcanvas"]').on("click", function () {
      $(".offcanvas-collapse").toggleClass("open");
      $(".navbar-toggler").toggleClass("collapsed");
    });
    $('[data-toggle="offcanvas"]').on("click", function () {
      $("body").toggleClass("opened-menu");
    });

    body.classList.remove("login-page");

    setCurrentRoute(location.pathname);
  }, [location.pathname]);

  const closeModal = (route) => {
    setHasRequest(false);
    dispatch(closeRequestModal());
    if (location.pathname.includes("programs") && route.includes("programs")) {
      window.location.reload();
    } else if (route == "/") {
      window.location.reload();
    } else {
      history.push(route);
    }
  };

  const openTimeZoneModal = () => {
    setTimeZoneModal(true);
    setshowTimeZoneValidationMsg(false);

  };

  const closeTimeZoneModal = () => {
    setTimeZoneModal(false);
  };

  const changeDropDown = (timeZoneId) => {
    setTimeZoneSelectedId(timeZoneId);
    setshowTimeZoneValidationMsg(false);
  };

  const clickUpdateTimeZone = () => {
    if (timeZoneSelectedId == null) {
      setshowTimeZoneValidationMsg(true);
    } else {
      dispatch(postTimeZone(timeZoneSelectedId))
        .then(function (res) {
          setCurrentTimeZone(timeZoneSelectedId);
          notification.success(
            localizationService.getLocalizationValue(
              "timezoneUpdatedSuccess",
              selectedlang
            )
          );
          if (!openRequestModal) {
            window.location.reload();
          }
        })
        .catch(function (res) {});
    }
  };

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-expand-lg fixed-top navbar-light"
        aria-label="Main navigation"
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img className="logo-nav" src={navLogo} alt="" />
          </Link>
          <button
            type="button"
            className="navbar-toggler p-0 border-0 collapsed"
            id="offcanvas"
            data-toggle="offcanvas"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>

          <div
            className="navbar-collapse offcanvas-collapse"
            id="navbarsExampleDefault"
          >
            <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
              <li className={"nav-item " + (currentRoute === "/" && "active")}>
                <Link className="nav-link" to="/">
                  {localizationService.getLocalizationValue(
                    "sessionHeading",
                    selectedlang
                  )}
                </Link>
              </li>
              <li
                className={
                  "nav-item " + (currentRoute === "/materials" && "active")
                }
              >
                <Link className="nav-link" to="/materials">
                  {localizationService.getLocalizationValue(
                    "materialsTitle",
                    selectedlang
                  )}
                </Link>
              </li>
              <li
                className={
                  "nav-item " + (currentRoute === "/programs" && "active")
                }
              >
                <Link className="nav-link" to="/programs">
                  {localizationService.getLocalizationValue(
                    "programsTitle",
                    selectedlang
                  )}
                </Link>
              </li>
            </ul>
            {currentRoute !== "/" ? (
              <button
                className="btn btn-outline-secondary time-zone-btn mobile"
                onClick={() => openTimeZoneModal()}
              >
                {currentTimeZone
                  ? timeZones.find((x) => x.id == currentTimeZone).name
                  : "Set Time Zone"}
              </button>
            ) : null}
            <div className="d-flex align-items-center">
              <a className="mr-4 wred-link">{userName}</a>
              {currentRoute != "/" ? (
                <button
                  className="btn btn-outline-secondary time-zone-btn desktop"
                  onClick={() => openTimeZoneModal()}
                >
                  {currentTimeZone
                    ? timeZones.find((x) => x.id == currentTimeZone).name
                    : "Set Time Zone"}
                </button>
              ) : null}
              <button
                className="btn btn-outline-secondary btn-hover-wred ico-l"
                onClick={() => onLogout()}
                type="button"
              >
                <i className="fal fa-sign-out"></i>
                {localizationService.getLocalizationValue(
                  "sideMenuLogoutTitle",
                  selectedlang
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>
      <main className="py-3">{props.children}</main>
      {currentTimeZone === null ? (
        <div
          className="modal fade show"
          id="wagon-confirm-dialog"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          style={
            timeZoneCheck === false ? { display: "none" } : { display: "block" }
          }
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-white" id="exampleModalLabel">
                  {localizationService.getLocalizationValue(
                    "selectTimeZone",
                    selectedlang
                  )}
                </h5>
              </div>
              <div className="modal-body">
                <div className="container mt-3">
                  <div className="row">
                    <div className="col text-center">
                      <div className="alert" role="alert" style={{}}>
                        <p>
                          {currentTimeZone !== null
                            ? localizationService.getLocalizationValue(
                                "currentTimeZone",
                                selectedlang
                              ) +
                              ": " +
                              timeZones.find((x) => x.id == currentTimeZone)
                                .name
                            : localizationService.getLocalizationValue(
                                "timeZoneNotSetModal1",
                                selectedlang
                              )}
                        </p>
                        <p>
                          {currentTimeZone !== null
                            ? localizationService.getLocalizationValue(
                                "currentTimeZone",
                                selectedlang
                              ) +
                              ": " +
                              timeZones.find((x) => x.id == currentTimeZone)
                                .name
                            : localizationService.getLocalizationValue(
                                "timeZoneNotSetModal2",
                                selectedlang
                              )}
                        </p>
                        <div className="btn-group">
                          <button
                            className="btn btn-secondary btn-sm dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {timeZoneSelectedId == null
                              ? localizationService.getLocalizationValue(
                                  "selectTimeZone",
                                  selectedlang
                                )
                              : timeZones.find(
                                  (x) => x.id === timeZoneSelectedId
                                ).name}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {timeZones.map((tz, tzIndex) => (
                              <button
                                className="dropdown-item"
                                key={tzIndex}
                                onClick={() => changeDropDown(tz.id)}
                                href=""
                              >
                                {tz.name}
                              </button>
                            ))}
                          </div>
                        </div>
                        <button
                          onClick={() => clickUpdateTimeZone()}
                          type="button"
                          className="btn btn-sm  btn-secondary"
                          style={{ float: "", marginLeft: "7px" }}
                        >
                          {localizationService.getLocalizationValue(
                            "updateTimeZone",
                            selectedlang
                          )}
                        </button>
                      </div>
                      {showTimeZoneValidationMsg ? (
                        <p className="time-zone-validation-msg">
                          {localizationService.getLocalizationValue(
                            "timeZoneValidationMsg",
                            selectedlang
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
          </div>
        </div>
      ) : null}

      {timeZoneModal ? (
        <div
          className="modal fade show"
          id="wagon-confirm-dialog"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          style={
            timeZoneModal === false ? { display: "none" } : { display: "block" }
          }
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-white" id="exampleModalLabel">
                  {localizationService.getLocalizationValue(
                    "selectTimeZone",
                    selectedlang
                  )}
                </h5>
              </div>
              <div className="modal-body">
                <div className="container mt-3">
                  <div className="row">
                    <div className="col text-center">
                      <div className="alert" role="alert" style={{}}>
                        <p>
                          {currentTimeZone !== null
                            ? localizationService.getLocalizationValue(
                                "currentTimeZone",
                                selectedlang
                              ) +
                              ": " +
                              timeZones.find((x) => x.id == currentTimeZone)
                                .name
                            : localizationService.getLocalizationValue(
                                "timeZoneNotSet",
                                selectedlang
                              )}
                        </p>
                        <div className="btn-group">
                          <button
                            className="btn btn-secondary btn-sm dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {timeZoneSelectedId == null
                              ? localizationService.getLocalizationValue(
                                  "selectTimeZone",
                                  selectedlang
                                )
                              : timeZones.find(
                                  (x) => x.id === timeZoneSelectedId
                                ).name}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {timeZones.map((tz, tzIndex) => (
                              <button
                                className="dropdown-item"
                                key={tzIndex}
                                onClick={() => changeDropDown(tz.id)}
                                href=""
                              >
                                {tz.name}
                              </button>
                            ))}
                          </div>
                        </div>
                        <button
                          onClick={() => clickUpdateTimeZone()}
                          type="button"
                          className="btn btn-sm  btn-secondary"
                          style={{ float: "", marginLeft: "7px" }}
                        >
                          {localizationService.getLocalizationValue(
                            "updateTimeZone",
                            selectedlang
                          )}
                        </button>
                      </div>
                      {showTimeZoneValidationMsg ? (
                          
                          <p className="time-zone-validation-msg">
                            {localizationService.getLocalizationValue(
                              "timeZoneValidationMsg",
                              selectedlang
                            )} 
                          </p>
                        ) : null}
                    </div>
                  </div>
                </div>
              </div>
              &nbsp;
              <div className="modal-footer">
                <div className="mx-auto">
                  <button
                    className="btn btn-secondary"
                    onClick={closeTimeZoneModal}
                  >
                    {" "}
                    {localizationService.getLocalizationValue(
                      "close",
                      selectedlang
                    )}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {hasRequest === true ? (
        <div>
          <div
            className="modal fade show"
            id="wagon-confirm-dialog"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header programs">
                  <h5 className="modal-title text-white" id="exampleModalLabel">
                    {localizationService.getLocalizationValue(
                      "programIntroTitle",
                      selectedlang
                    )}
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="container mt-3">
                    <div className="row">
                      <div className="col">
                        <div>
                          {localizationService.getLocalizationValue(
                            "programIntroText1",
                            selectedlang
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                &nbsp;
                <div className="modal-footer">
                  <div className="mx-auto">
                    <button
                      onClick={() => closeModal("/programs")}
                      className="btn btn-secondary program-color"
                    >
                      {localizationService.getLocalizationValue(
                        "chooseOption",
                        selectedlang
                      )}
                    </button>
                    <button
                      onClick={() => closeModal("/")}
                      className="btn btn-secondary"
                      style={{ float: "", marginLeft: "7px" }}
                    >
                      {localizationService.getLocalizationValue(
                        "laterOption",
                        selectedlang
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default MasterPage;
