import config from '../config';

/**
 * @param {string} type
 * @param {string} url
 * @param {object} data
 * @param {boolean} isAuthorized
 */
export function http(type, url, data, isAuthorized = true) {
    return new Promise((resolve, reject) => {
        fetch(url,
            {
                method: type,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: isAuthorized ? 'Bearer ' + sessionStorage.getItem(config.TOKEN) : null
                },
                body: data ? JSON.stringify(data) : null,
            })
            .then(function (res) {
                if (res.status === 200) {
                    let jsonPromise = res.json();
                    jsonPromise.then(function (object) {
                        return resolve(object);
                    }).catch(function (res) {
                        return resolve();
                    });
                    return jsonPromise;
                } else {
                    let jsonPromise = res.json();
                    jsonPromise.then(function (object) {
                        return reject(object);
                    }).catch(function (res) {
                        return reject();
                    });
                    return jsonPromise;
                }
            })
            .then(function (res) {
                resolve(res);
            })
            .catch(function (res) {
                reject(res);
            });
    });
}


/**
 * @param {string} entity
 * @param {number} from
 * @param {number} page
 * @param {string} orderBy
 */
export function getEntities(entity, from = 0, page = 50, orderBy = "default") {
    let url = `${config.API_ROOT_URL}/${entity}?from=${from}&page=${page}&orderBy=${orderBy}`;
    return http("GET", url, null);
}




/**
 * @param {string} entity
 * @param {number} id
 */
export function getEntity(entity, id) {
    let url = `${config.API_ROOT_URL}/${entity}/${id}`;
    return http("GET", url, null);
}

/**
 * @param {string} entity
 * @param {object} data
 */
export function createEntity(entity, data) {
    let url = `${config.API_ROOT_URL}/${entity}`;
    return http("POST", url, data);
}

/**
 * @param {string} entity
 * @param {number} id
 * @param {object} data
 */
export function updateEntity(entity, id, data) {
    let url = `${config.API_ROOT_URL}/${entity}/${id}`;
    return http("PUT", url, data);
}

/**
 * @param {string} entityName
 * @param {number} id
 * @param {boolean} isActivate
 * @param {string} message
 */
export function activateEntity(entityName, id, isActivate, message) {
    var data = {
        isActive: isActivate,
        message: message
    };
    let url = `${config.API_ROOT_URL}/${entityName}/${id}/activate`;
    return http("PUT", url, data);
}



/**
 * @param {string} entity
 * @param {number} id
 * @param {Array} data
 */
export function changeEntity(entity, id, data) {
    let url = `${config.API_ROOT_URL}/${entity}/${id}`;
    return http("PATCH", url, data);
}
