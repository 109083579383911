import { Localizations } from "../localizations";

export default {
  getLocalizationValue: function (key, lang) {
    let result = Localizations[key];
    if (result !== undefined) {
      if (lang === "fr-CA") {
        return result.fr;
      } else {
        return result.en;
      }
    } else {
      return "";
    }
  },
};
